export function maskMobile(mobile: string, displayedLength: number) {
  let maskedMobile = '';

  let i = 0;
  for (i; i < mobile.length - displayedLength; i++) {
    maskedMobile += '*';
  }
  maskedMobile += mobile.substring(i, mobile.length);

  return maskedMobile;
}