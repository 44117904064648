<ng-container *ngIf="isSelectPhoneCode">
    <app-signup-header [progress]="PROGRESS.SIGNUP_MEMBER" (onBackClick)="this.isSelectPhoneCode = false;"></app-signup-header>
    <app-country-select *ngIf="isSelectPhoneCode"
        (onCountrySelected)="onCountrySelected($event);this.isSelectPhoneCode=false;"></app-country-select>
</ng-container>

<ng-container *ngIf="!isSelectPhoneCode">
    <app-signup-header [progress]="PROGRESS.SIGNUP_MEMBER" (onBackClick)="onBackClick()"></app-signup-header>

    <div class="container">
        <div class="mx-auto mt-6 w-400">
            <h2 class="flex flex-wrap font-semibold justify-content-center" [innerHTML]="'MyQM.SelectQmMemberSignInMethod' | translate"></h2>

            <div class="flex justify-content-evenly w-full gap-3 mt-6">
                <div [ngClass]="selectedMethod === LOGIN_TYPE.EMAIL ? 'bg-primary' : ''" 
                    (click)="onChangeMethod(LOGIN_TYPE.EMAIL)"
                    class="border-1 border-primary flex flex-1 py-2 justify-content-center align-items-center font-bold border-round-lg cursor-pointer">
                    <span class="text-black-alpha-90 text-center">{{'MyQM.Email' | translate}}</span>
                </div>
                <div [ngClass]="selectedMethod === LOGIN_TYPE.MOBILE ? 'bg-primary' : ''" 
                    (click)="onChangeMethod(LOGIN_TYPE.MOBILE)"
                    class="border-1 border-primary flex flex-1 py-2 justify-content-center align-items-center font-bold border-round-lg cursor-pointer">
                    <span class="text-black-alpha-90 text-center">{{'MyQM.Mobile' | translate}}</span>
                </div>
                <div [ngClass]="selectedMethod === LOGIN_TYPE.USERNAME ? 'bg-primary' : ''"
                    (click)="onChangeMethod(LOGIN_TYPE.USERNAME)" 
                    class="border-1 border-primary flex flex-1 py-2 justify-content-center align-items-center font-bold border-round-lg cursor-pointer">
                    <span class="text-black-alpha-90 text-center">{{'MyQM.Username' | translate}}</span>
                </div>
            </div>

            <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
                <ng-container *ngIf="selectedMethod === LOGIN_TYPE.EMAIL"> <!-- Email -->
                    <span class="flex p-float-label myqm-field mt-6">
                        <input id="inputEmail" type="text" pInputText formControlName="email" class="w-full font-bold input-clearable" maxlength="64" />
                        <label for="inputEmail">{{'MyQM.LabelEmail' | translate}}</label>
                        <div [ngClass]="loginForm.controls['email'].value === '' ? 'hidden' : 'flex align-items-center'" class="div-clear-input">
                            <p-button styleClass="border-none shadow-none pl-2 pr-0 btn-clear-input">
                                <img src="./assets/images/common/clear_input.svg" alt="Clear Input" width="15" (click)="loginForm.controls['email'].setValue('')" />
                            </p-button>
                        </div>
                    </span>
                </ng-container>
                <ng-container *ngIf="selectedMethod === LOGIN_TYPE.USERNAME"> <!-- Username -->
                    <span class="flex p-float-label myqm-field mt-6">
                        <input id="inputUsername" type="text" pInputText formControlName="username" class="w-full font-bold input-clearable" />
                        <label for="inputUsername">{{'MyQM.LabelUsername' | translate}}</label>
                        <div [ngClass]="loginForm.controls['username'].value === '' ? 'hidden' : 'flex align-items-center'" class="div-clear-input">
                            <p-button styleClass="border-none shadow-none pl-2 pr-0 btn-clear-input">
                                <img src="./assets/images/common/clear_input.svg" alt="Clear Input" width="15" (click)="loginForm.controls['username'].setValue('')" />
                            </p-button>
                        </div>
                    </span>
                </ng-container>
                <ng-container *ngIf="selectedMethod === LOGIN_TYPE.MOBILE"> <!-- Mobile -->
                    <div class="mt-6">
                        <label for="inputMobile" class="text-sm">{{'MyQM.PhoneNumber' | translate}}<span class="text-w300">*</span></label>
                        <div class="flex">
                            <!-- Country code and icon -->
                            <div (click)="this.isSelectPhoneCode = true"
                                class="flex mr-2 bg-n30 border-n30 border-round-3xl align-items-center cursor-pointer">
                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                                    [class]="'flag flag-'+ countryCode.toLowerCase() +' ml-3 mr-2'" style="height: 20px" />
                                <b class="pr-3 white-space-nowrap">+{{ phoneCode }}</b>
                            </div>
                            <div class="flex myqm-field w-full">
                                <input id="inputMobile" type="tel" pInputText formControlName="mobile"
                                    class="w-full font-bold input-clearable" minlength="4" maxlength="13"
                                    [pKeyFilter]="PHONE_REG_EXP" />
                                <div [ngClass]="loginForm.controls['mobile'].value === '' ? 'hidden' : 'flex align-items-center'" class="div-clear-input">
                                    <p-button styleClass="border-none shadow-none pl-2 pr-0 btn-clear-input">
                                        <img src="./assets/images/common/clear_input.svg" alt="Clear Input" width="15"
                                            (click)="loginForm.controls['mobile'].setValue('');" />
                                    </p-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <span class="p-float-label myqm-field mt-6">
                    <p-password inputId="inputPassword" formControlName="password" [toggleMask]="true" [feedback]="false" styleClass="w-full" inputStyleClass="w-full font-bold">
                        <ng-template pTemplate="showicon">
                            <div class="flex h-full py-auto px-2 cursor-pointer">
                                <img src="./assets/images/common/eye_closed.svg" alt="Show Password" style="width: 25px;" />
                            </div>
                        </ng-template>
                        <ng-template pTemplate="hideicon">
                            <div class="flex h-full py-auto px-2 cursor-pointer">
                                <img src="./assets/images/common/eye_open.svg" alt="Hide Password" style="width: 25px;" />
                            </div>
                        </ng-template>
                    </p-password>
                    <label for="inputPassword">{{'MyQM.LabelPassword' | translate}}</label>
                </span>

                <div class="flex flex-column gap-3 mt-6">
                    <p-button 
                        type="submit"
                        [label]="'MyQM.Login' | translate"
                        styleClass="btn-custom-primary w-full"
                        [disabled]="loginForm.invalid"
                    >
                    </p-button>
                </div>
            </form>
        </div>
    </div>
</ng-container>