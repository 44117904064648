<div class="bg-n40">
    <div class="container text-center py-3 md:py-6">
        <img src="./assets/images/seller/banner.svg" class="w-full">
    </div>
</div>


<div *ngIf="activeStep$ | async as activeStep">
    <app-seller-login *ngIf="activeStep === 'login'"></app-seller-login>
    <app-seller-phone-verify *ngIf="activeStep === 'phone'"></app-seller-phone-verify>
    <app-seller-captcha *ngIf="activeStep === 'captcha'"></app-seller-captcha>
    <app-seller-otp *ngIf="activeStep === 'otp'"></app-seller-otp>
    <app-seller-redirect *ngIf="activeStep === 'redirect'"></app-seller-redirect>
</div>