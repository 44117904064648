import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-seller-redirect',
  templateUrl: './seller-redirect.component.html',
  styleUrls: ['./seller-redirect.component.scss']
})
export class SellerRedirectComponent {
  onRedirectSellerPortal(){
    location.href = environment.ECOMMERCE_LOGIN_URL;
  }
}
