<ng-container *ngIf="isSelectPhoneCode">
    <app-delete-driver-header (onBackClick)="this.isSelectPhoneCode = false;"></app-delete-driver-header>
    <app-country-select *ngIf="isSelectPhoneCode"
        (onCountrySelected)="onCountrySelected($event);this.isSelectPhoneCode=false;"></app-country-select>
</ng-container>

<ng-container *ngIf="!isSelectPhoneCode">
    <app-delete-driver-header [showBack]="false"></app-delete-driver-header>
    <div class="container">
        <div class="mx-auto text-center w-400">
            <div class="mt-5 text-left">
                <label for="mobile" class="text-xs">{{'MyQM.PhoneNumber' | translate}}<span
                        class="text-w300">*</span></label>
                <div class="flex">
                    <!-- Country code and icon -->
                    <div (click)="this.isSelectPhoneCode = true"
                        class="flex mr-2 bg-n30 border-n30 border-round-3xl align-items-center cursor-pointer">
                        <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                            [class]="'flag flag-'+ countryCode.toLowerCase() +' ml-3 mr-2'" style="height: 20px" />
                        <b class="pr-3 white-space-nowrap">+{{ phoneCode }}</b>
                    </div>
                    <div class="flex myqm-field w-full">
                        <input type="tel" pInputText [(ngModel)]="mobile" class="w-full font-bold input-clearable"
                            minlength="4" maxlength="13" [pKeyFilter]="phoneRegExp" />
                        <div [ngClass]="mobile === '' ? 'hidden' : 'flex align-items-center'" class="div-clear-input">
                            <p-button styleClass="border-none shadow-none pl-2 pr-0 btn-clear-input">
                                <img src="./assets/images/common/clear_input.svg" alt="Clear Input" width="15"
                                    (click)="mobile = '';" />
                            </p-button>
                        </div>
                    </div>
                </div>
            </div>

            <span class="p-float-label myqm-field mt-6">
                <p-password inputId="inputPassword" [(ngModel)]="password" [toggleMask]="true" [feedback]="false" [maxLength]="16"
                    styleClass="w-full" inputStyleClass="w-full font-bold">
                    <ng-template pTemplate="showicon">
                        <div class="flex h-full py-auto px-2 cursor-pointer">
                            <img src="./assets/images/common/eye_closed.svg" alt="Show Password" style="width: 25px;" />
                        </div>
                    </ng-template>
                    <ng-template pTemplate="hideicon">
                        <div class="flex h-full py-auto px-2 cursor-pointer">
                            <img src="./assets/images/common/eye_open.svg" alt="Hide Password" style="width: 25px;" />
                        </div>
                    </ng-template>
                </p-password>
                <label for="inputPassword">{{'MyQM.LabelPasswordDigit' | translate}}</label>
            </span>

            <p-button (onClick)="onLoginNext()" type="submit" [label]="'MyQM.Login' | translate"
                styleClass="btn-allow-disable w-full mt-8" [style]="{  
                    'color': 'black',
                }" [disabled]="checkButtonDisabled()">
            </p-button>
        </div>
    </div>
</ng-container>
<app-toast-error></app-toast-error>