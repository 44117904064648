import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  templateUrl: './delete-driver.component.html',
  styleUrls: ['./delete-driver.component.scss'],
  host: { 'module': 'driver-delete' }
})
export class DeleteDriverComponent {
  activeStep$ = this.userService.step$;

  constructor(private userService: UserService) { 
    this.userService.redirect('login');
  }
}
