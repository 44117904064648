import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { RegisterService } from 'src/app/services/register.service';
import { SellerService } from 'src/app/services/seller.service';
import { UserService } from 'src/app/services/user.service';
import { Source } from 'src/app/shared/models/enum.model';
import { validatePhoneNumber } from 'src/app/shared/models/validators.model';

@Component({
  selector: 'app-seller-phone-verify',
  templateUrl: './seller-phone-verify.component.html',
  styleUrls: ['./seller-phone-verify.component.scss']
})
export class SellerPhoneVerifyComponent {

  phoneRegExp: RegExp = /^\d+$/;
  mobile: string | number;
  phoneCode: string;

  verifyPhoneSubscription: Subscription | undefined;

  constructor(
    private sellerService: SellerService,
    private userService: UserService,
    private registerService: RegisterService
  ) {
    this.mobile = this.userService.getPhone();
    this.phoneCode = this.userService.getPhoneCode();
  }

  checkButtonDisabled() {
    if (!validatePhoneNumber(this.mobile)) {
      return true;
    }
    return false;
  }

  onVerifyPhone() {
    this.verifyPhoneSubscription = this.registerService.verifyPhone(
      this.phoneCode,
      this.mobile.toString(),
      this.userService.getLoginToken(),
      Source.ECOMMERCE
    ).subscribe((res) => {
      if (res) {
        this.userService.setPhoneInfo(this.phoneCode, this.mobile.toString());
        this.sellerService.redirect('captcha');
      } else {
        alert('Phone not verified');
      }
    });
  }

  ngOnDestroy() {
    this.verifyPhoneSubscription?.unsubscribe();
  }
}
