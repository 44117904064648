import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DriverService {
  private apiUrl = environment.TMS_API_URL;

  private stepSubject: BehaviorSubject<string>;
  public step$: Observable<string>;

  constructor(private http: HttpClient) {
    this.stepSubject = new BehaviorSubject<string>('login');
    this.step$ = this.stepSubject.asObservable();
  }

  redirect(step: string) {
    this.stepSubject.next(step);
  }

  login(phoneCode: string, phone: string, password: string) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/driver/login`, {
        phoneCode: phoneCode,
        phone: phone,
        password: password
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  deleteAccount(reason: string, reasonContent: string) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/driver/delete`, {
        reason: reason,
        reasonContent: reasonContent
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }
}
