import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private apiUrl = environment.TMS_API_URL;
  private loading: boolean = false;

  showLoader(show: boolean) {
    this.loading = show;
  }

  isLoading() {
    return this.loading;
  }

  uploadFile(file: File) {
    let formData = new FormData();
    formData.append('file', file);

    return this.http
      .post<any>(`${this.apiUrl}/web/v1/common/uploadFile`, formData)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  constructor(private http: HttpClient) { }
}
