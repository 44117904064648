<div class="container pt-4">
    <div class="flex text-center justify-content-center mt-4">
        <h2 class="font-semibold">{{'MyQM.CompletePuzzle' | translate}}</h2>
    </div>
    <app-captcha (onVerifyCaptcha)="onVerifyCaptcha($event)"></app-captcha>
    <div class="mx-auto mt-6" style="max-width: 385px;">
        <div class="flex flex-column">
            <p-button [label]="'MyQM.Next' | translate" styleClass="btn-allow-disable" [disabled]="!isCaptchaVerified"
                (onClick)="redirectToOtp();" [style]="{ 
                'width': '100%', 
                'color': 'black',
            }">
            </p-button>
        </div>
    </div>
</div>