<div class="container">
    <div class="mx-auto mt-6 text-center w-400">
        <h1 class="font-semibold hidden md:block">{{'MyQM.SignUp' | translate}}</h1>

        <div class="flex align-items-center" *ngIf="showProgressBar">
            <span (click)="back()" class="pi pi-chevron-left mr-3 text-p300 font-bold text-xl cursor-pointer"></span>
            <div class="flex-1">
                <p-progressBar [value]="progress" [showValue]="false" [style]="{ height: '10px' }" color="var(--sg100)"></p-progressBar>
            </div>
        </div>
    </div>
</div>