import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-delete-user-login-password',
  templateUrl: './delete-user-login-password.component.html',
  styleUrls: ['./delete-user-login-password.component.scss'],
  providers: [MessageService]
})
export class DeleteUserLoginPasswordComponent {
  password: string = '';

  redirect(step: string) {
    this.userService.redirect(step);
  }

  onBackClick() {
    this.userService.redirect('login');
  }

  onLoginOtpClick() {
    this.userService.setIsPasswordLogin(false);
    this.redirect('login-captcha');
  }

  onLoginClick() {
    this.userService.login({
      phoneCode: this.userService.getPhoneCode(),
      phone: this.userService.getPhone(),
      loginType: 'password',
      code: '',
      password: this.password,
      source: this.userService.getLoginSource()
    }).subscribe(res => {
      if (res.code === 200) {
        this.userService.setLoginToken(res.data.token);
        this.redirect('delete-confirm');
      } else {
        this.messageService.add({ detail: 'Verification code error' });
      }
    });
  }

  constructor(private userService: UserService, private messageService: MessageService) { }
}
