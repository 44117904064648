import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { DriverService } from 'src/app/services/driver.service';
import { UserService } from 'src/app/services/user.service';
import { Country } from 'src/app/shared/models/country.model';
import { PhoneCode } from 'src/app/shared/models/enum.model';
import { validatePhoneNumber } from 'src/app/shared/models/validators.model';

@Component({
  selector: 'app-delete-driver-login',
  templateUrl: './delete-driver-login.component.html',
  styleUrls: ['./delete-driver-login.component.scss'],
  providers: [MessageService]
})
export class DeleteDriverLoginComponent {
  isSelectPhoneCode: boolean = false;

  countryCode: string = 'MY';
  phoneCode: string = PhoneCode.MYS;
  mobile: string | number = '';
  phoneRegExp: RegExp = /^\d+$/;
  password: string = '';

  verifyPhoneSubscription: Subscription | undefined;

  redirect(step: string) {
    this.userService.redirect(step);
  }

  checkButtonDisabled() {
    if (!validatePhoneNumber(this.mobile) || this.password.length < 6) {
      return true;
    }
    return false;
  }

  onCountrySelected(country: Country) {
    this.phoneCode = country.mobileCode;
    this.countryCode = country.code;
  }

  onLoginNext() {
    this.driverService.login(this.phoneCode, this.mobile.toString(), this.password).subscribe((res) => {
      console.log(res);
      if (res.code === 200) {
        this.userService.setLoginToken(res.data.token);
        this.redirect('delete-confirm');
      } else {
        this.messageService.add({ detail: res.msg || res.toString() });
      }
    })
  }

  ngOnDestroy() {
    this.verifyPhoneSubscription?.unsubscribe();
  }

  constructor(private userService: UserService, private driverService: DriverService, private messageService: MessageService) { }
}
