import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent {
  activeStep$ = this.userService.step$;

  constructor(private userService: UserService) { 
    this.userService.redirect('login-home');
  }
}
