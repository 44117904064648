import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { Country } from 'src/app/shared/models/country.model';
import { PhoneCode } from 'src/app/shared/models/enum.model';
import { validatePhoneNumber } from 'src/app/shared/models/validators.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-login-home',
  templateUrl: './user-login-home.component.html',
  styleUrls: ['./user-login-home.component.scss'],
  providers: [MessageService]
})
export class UserLoginHomeComponent {
  isLoading = false;
  tncUrl = environment.TNC_URL;
  isSelectPhoneCode: boolean = false;

  countryCode: string = 'MY';
  phoneCode: string = PhoneCode.MYS;
  mobile: string | number = '';
  phoneRegExp: RegExp = /^\d+$/;
  ackChecked: boolean = false;

  verifyPhoneSubscription: Subscription | undefined;

  redirect(step: string) {
    this.userService.redirect(step);
  }

  checkButtonDisabled() {
    if (!validatePhoneNumber(this.mobile) || !this.ackChecked) {
      return true;
    }
    return false;
  }

  onCountrySelected(country: Country) {
    this.phoneCode = country.mobileCode;
    this.countryCode = country.code;
  }

  onLoginNext() {
    this.isLoading = true;
    this.verifyPhoneSubscription = this.userService.verifyPhone(this.mobile.toString()).subscribe((res) => {
      this.isLoading = false;
      this.userService.setPhoneInfo(this.phoneCode, this.mobile.toString());
      if (res.data) {
        if (res.data.result === 'password') {
          this.userService.setHasPasswordLogin(true);
          this.userService.setIsPasswordLogin(true);
          this.redirect('login-password');
        } else if (res.data.result === 'code') {
          this.userService.setHasPasswordLogin(false);
          this.userService.setIsPasswordLogin(false);
          this.redirect('login-captcha');
        } else {
          this.messageService.add({ detail: 'Unregistered user, please sign up' });
        }
      }
    });
  }

  onBackClick() {
    this.router.navigate(['..'], {
      relativeTo: this.route,
      queryParams: {
        callbackUrl: this.userService.getCallbackUrl()
      },
      replaceUrl: true,
    });
  }

  ngOnDestroy() {
    this.verifyPhoneSubscription?.unsubscribe();
  }

  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute, private messageService: MessageService) { }
}
