import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss']
})
export class LoginHeaderComponent {
  @Output() onBackClick = new EventEmitter<boolean>();

  back(){
    this.onBackClick.emit(true);
  }
}
