<app-delete-user-header (onBackClick)="onBackClick()"></app-delete-user-header>
<div class="container">
    <div class="mx-auto text-center w-400">
        <h2 class="font-semibold">{{'MyQM.CompletePuzzle' | translate}}</h2>

        <app-captcha (onVerifyCaptcha)="onVerifyCaptcha($event)"></app-captcha>
        <div class="mx-auto mt-6" style="max-width: 385px;">
            <div class="flex flex-column">
                <p-button [label]="'MyQM.Next' | translate" styleClass="btn-allow-disable" [disabled]="!isCaptchaVerified"
                    (onClick)="redirectToLogin();" [style]="{ 
                    'width': '100%', 
                    'color': 'black',
                }">
                </p-button>
            </div>
        </div>
    </div>
</div>