import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
  host: { 'module': 'user-delete' }
})
export class DeleteComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle('Delete Account');
  }
}
