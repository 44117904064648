import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { DriverService } from 'src/app/services/driver.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-delete-driver-confirm',
  templateUrl: './delete-driver-confirm.component.html',
  styleUrls: ['./delete-driver-confirm.component.scss'],
  providers: [MessageService]
})
export class DeleteDriverConfirmComponent {
  reasonExtra = '';
  ackChecked = false;
  selectedReason = null;
  visible: boolean = false;

  reasons = [
    { key: 'MyQM.ReasonAnotherAccount', value: '1' },
    { key: 'MyQM.ReasonReside', value: '2' },
    { key: 'MyQM.ReasonNotSatisfied', value: '3' },
    { key: 'MyQM.ReasonNoNeed', value: '4' }
  ];

  deleteAccountSubscription: Subscription | undefined;

  constructor(private userService: UserService, private driverService: DriverService, private messageService: MessageService) {
  }

  ngOnInit() { }

  checkButtonDisabled() {
    if (this.selectedReason !== null && this.ackChecked) {
      return false;
    }
    return true;
  }

  showDialog() {
    this.visible = true;
  }

  hideDialog() {
    this.visible = false;
  }

  onDeleteAccount() {
    this.deleteAccountSubscription = this.driverService.deleteAccount(this.selectedReason || '', this.reasonExtra).subscribe((res) => {
      if (res.code === 200) {
        this.userService.redirect('delete-success');
      } else {
        this.messageService.add({ detail: res.msg || res.toString() });
      }
    });
  }

  ngOnDestroy() {
    this.deleteAccountSubscription?.unsubscribe();
  }
}
