import { Component } from '@angular/core';
import { SellerService } from 'src/app/services/seller.service';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.scss']
})
export class SellerComponent {
  activeStep$ = this.sellerService.step$;

  constructor(private sellerService: SellerService, private titleService: Title) {
    this.titleService.setTitle('MyQM Mall Seller Registration');
  }
}
