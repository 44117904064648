import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginInfo } from '../shared/models/api-input.model';

@Injectable({
  providedIn: 'root'
})
export class QmMemberService {

  private apiUrl = environment.TMS_API_URL;

  login(loginInfo: LoginInfo) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/qm/member/login`, loginInfo)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  getTokenInfo(token: string) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/qm/member/getTokenInfo`, {
        token: token
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  constructor(private http: HttpClient) { }
}
