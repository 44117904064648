<div class="bg-n40">
    <div class="container text-center py-3 md:py-6">
        <img src="./assets/images/driver/banner.svg" class="w-full">
    </div>
</div>

<div *ngIf="activeStep$ | async as activeStep">
    <app-driver-login *ngIf="activeStep === 'login'"></app-driver-login>
    <app-driver-phone-verify *ngIf="activeStep === 'phone'"></app-driver-phone-verify>
    <app-driver-captcha *ngIf="activeStep === 'captcha'"></app-driver-captcha>
    <app-driver-otp *ngIf="activeStep === 'otp'"></app-driver-otp>
</div>