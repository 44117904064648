import { Component } from '@angular/core';
import { SellerService } from 'src/app/services/seller.service';

@Component({
  selector: 'app-seller-captcha',
  templateUrl: './seller-captcha.component.html',
  styleUrls: ['./seller-captcha.component.scss']
})
export class SellerCaptchaComponent {
  isCaptchaVerified: boolean = false;

  constructor(private sellerService: SellerService) {
  }

  onVerifyCaptcha(verified: boolean) {
    this.isCaptchaVerified = verified;
  }

  redirectToOtp() {
    this.sellerService.redirect('otp');
  }
}
