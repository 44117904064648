import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-signup',
  templateUrl: './user-signup.component.html',
  styleUrls: ['./user-signup.component.scss']
})
export class UserSignupComponent {
  activeStep$ = this.userService.step$;

  constructor(private userService: UserService, private route: ActivatedRoute) {
    let referralCode = this.route.snapshot.queryParamMap.get('ref');
    if (referralCode) {
      this.userService.setIsReferralLink(true);
      this.userService.setReferralCode(referralCode);
    }

    this.userService.redirect('register-guest');
  }
}
