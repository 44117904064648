<div class="container">
    <div class="mx-auto mt-6 text-center w-400">
        <div class="flex align-items-center">
            <div *ngIf="showBack" (click)="back()"
                class="bg-p300 border-circle cursor-pointer flex justify-content-center align-items-center"
                style="width: 35px; height: 35px;">
                <span class="pi pi-chevron-left"></span>
            </div>
            <div class="flex-1">
                <h1 class="font-semibold">{{'MyQM.DeleteTitle' | translate}}</h1>
            </div>
        </div>

        <img src="./assets/images/MyQM Logo.svg" height="90" class="my-4">
    </div>
</div>