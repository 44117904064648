<div class="container">
    <div class="mx-auto text-center mt-6 w-450">
        <h1 class="font-semibold">{{'MyQM.DeleteAccount' | translate}}</h1>

        <div class="mt-6 text-left">
            <h2 class="font-semibold">{{'MyQM.WhyDeleteAccount' | translate}}</h2>
        </div>

        <div class="flex flex-column gap-1">
            <div *ngFor="let reason of reasons" class="field-checkbox">
                <p-radioButton [inputId]="reason.key" name="reason" [value]="reason.key | translate"
                    [(ngModel)]="selectedReason"></p-radioButton>
                <label [for]="reason.key" class="ml-2 text-left">{{ reason.key | translate }}</label>
            </div>
        </div>

        <div class="relative">
            <textarea rows="3" pInputTextarea [autoResize]="true" maxlength="100" [placeholder]="'MyQM.TellUsMore' | translate"
                [(ngModel)]="reasonExtra" class="w-full border-round-2xl mt-2 pb-5"></textarea>
            <span class="absolute bottom-0 right-0 py-3 px-4 text-sm font-italic text-n100">{{reasonExtra.length}} /
                100</span>
        </div>


        <div class="mt-2 text-left">
            <span class="text-n100" [innerHTML]="'MyQM.GetBackClarification' | translate"></span>
        </div>

        <div class="mt-6">
            <div class="flex text-left">
                <p-checkbox styleClass="p-checkbox-black" [(ngModel)]="ackChecked" [binary]="true"
                    inputId="ack"></p-checkbox>
                <label for="ack" class="ml-3">
                    {{'MyQM.DeleteConfirmConsent' | translate}}
                </label>
            </div>
        </div>

        <div class="mt-6">
            <p-button (onClick)="showDialog()" type="submit" [label]="'MyQM.Confirm' | translate"
                styleClass="btn-allow-disable w-full mt-3" [style]="{  
                    'color': 'black',
                }" [disabled]="checkButtonDisabled()">
            </p-button>
        </div>
    </div>
</div>

<p-dialog [(visible)]="visible" [modal]="true" styleClass="w-450" [draggable]="false" [resizable]="false">
    <div class="text-center">
        <span>{{'MyQM.DeleteConfirmation' | translate}}</span>
    </div>

    <div class="mt-4 grid">
        <div class="col">
            <p-button severity="secondary" (onClick)="hideDialog()"
                styleClass="w-full flex justify-content-center bg-n50 border-0 border-round-2xl py-2">
                <span class="text-900">{{'MyQM.Cancel' | translate}}</span>
            </p-button>
        </div>
        <div class="col">
            <p-button severity="danger" (onClick)="onDeleteAccount()"
                styleClass="w-full flex justify-content-center bg-w400 border-0 border-round-2xl py-2">
                <span class="text-n0">{{'MyQM.Confirm' | translate}}</span>
            </p-button>
        </div>
    </div>
</p-dialog>

<app-toast-error></app-toast-error>