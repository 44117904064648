<div class="container">
    <div class="mx-auto text-center w-400">
        <h2 class="font-semibold">{{'MyQM.SelectCountry' | translate}}</h2>

        <div class="flex myqm-field w-full">
            <input type="text" pInputText [(ngModel)]="searchText" (input)="onSearchCountry()"
                class="w-full input-clearable" [placeholder]="'MyQM.Search' | translate" />
            <div [ngClass]="searchText === '' ? 'hidden' : 'flex align-items-center'" class="div-clear-input">
                <p-button styleClass="border-none shadow-none pl-2 pr-0 btn-clear-input">
                    <img src="./assets/images/common/clear_input.svg" alt="Clear Input" width="15"
                        (click)="searchText = '';" />
                </p-button>
            </div>
        </div>

        <div class="flex flex-column mt-5 text-left" style="overflow-y: auto;" [style]="{'max-height': maxHeight}">
            <span class="text-500 my-3 text-sm" *ngIf="popularCountries.length !== 0">{{'MyQM.PopularCountry' | translate}}</span>
            <div (click)="onCountryClicked(country)"
                class="flex text-left align-items-center py-3 border-bottom-1 border-300 cursor-pointer hover:surface-200"
                *ngFor="let country of popularCountries">
                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                    [class]="'mr-3 flag flag-' + country.code.toLowerCase()" style="width: 35px" />
                <span>{{country.name}}</span>
                <span *ngIf="showMobileCode" class="ml-auto pr-2">+{{country.mobileCode}}</span>
            </div>

            <div *ngFor="let item of countriesMap | keyvalue">
                <div class="pt-6 text-sm text-500">{{item.key}}</div>
                <div (click)="onCountryClicked(country)"
                    class="flex text-left align-items-center py-3 border-bottom-1 border-300 cursor-pointer hover:surface-200"
                    *ngFor="let country of item.value">
                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                        [class]="'mr-3 flag flag-' + country.code.toLowerCase()" style="width: 35px" />
                    <span>{{country.name}}</span>
                    <span *ngIf="showMobileCode" class="ml-auto pr-2">+{{country.mobileCode}}</span>
                </div>
            </div>
        </div>
    </div>
</div>