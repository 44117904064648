import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-signup-header',
  templateUrl: './signup-header.component.html',
  styleUrls: ['./signup-header.component.scss']
})
export class SignupHeaderComponent {
  @Input() progress: number = 0;
  @Input() showProgressBar: boolean = true;  // optional, default is true

  @Output() onBackClick = new EventEmitter<boolean>();

  back(){
    this.onBackClick.emit(true);
  }
}
