import { Component } from '@angular/core';

@Component({
  selector: 'app-delete-driver-success',
  templateUrl: './delete-driver-success.component.html',
  styleUrls: ['./delete-driver-success.component.scss']
})
export class DeleteDriverSuccessComponent {

}
