import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { RegisterService } from 'src/app/services/register.service';
import { SellerService } from 'src/app/services/seller.service';
import { TimerService } from 'src/app/services/timer.service';
import { UserService } from 'src/app/services/user.service';
import { SendSmsCode, VerifyCode } from 'src/app/shared/models/api-input.model';
import { Source } from 'src/app/shared/models/enum.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-seller-otp',
  templateUrl: './seller-otp.component.html',
  styleUrls: ['./seller-otp.component.scss'],
  providers: [MessageService]
})
export class SellerOtpComponent {

  countdown!: number; // in seconds
  timerSubscription: Subscription | undefined;

  // subscription
  sendCodeSub: Subscription | undefined;
  verifyCodeSub: Subscription | undefined;

  constructor(private commonService: CommonService, private messageService: MessageService, private registerService: RegisterService, private sellerService: SellerService, private timerService: TimerService, private userService: UserService) { }

  ngOnInit() {
    this.sendCode();
  }

  startTimer() {
    this.countdown = 60;
    this.timerSubscription = this.timerService.getCounter().subscribe(() => {
      --this.countdown!;
      if (this.countdown === 0) {
        this.stopTimer();
      }
    });
  }

  stopTimer() {
    this.timerSubscription?.unsubscribe();
  }

  sendCode() {
    let sendSmsCode = new SendSmsCode(
      this.userService.getImageToken(),
      this.userService.getCaptchaX().toString(),
      this.userService.getCaptchaY().toString(),
      this.userService.getPhoneCode(),
      this.userService.getPhone(),
      Source.ECOMMERCE
    );

    this.sendCodeSub = this.registerService.sendCode(sendSmsCode).subscribe((res: any) => {
      this.startTimer();
      if (res.code === 200) {
        
      } else {
        console.log(res.msg);
        alert(res.msg);
      }
    });
  }

  requestNewOtp(requestNewOtp: boolean) {
    if (requestNewOtp) {
      this.sellerService.redirect('captcha');
    }
  }

  verifyCode(otp: string) {
    this.commonService.showLoader(true);

    let verifyCode = new VerifyCode(
      this.userService.getPhone(),
      otp,
      this.userService.getLoginToken(),
      Source.ECOMMERCE
    );

    this.verifyCodeSub = this.registerService.verifyCode(verifyCode).subscribe((res: any) => {
      this.commonService.showLoader(false);
      if (res.code === 200) {
        this.redirect(res.data.token);
      } else {
        this.messageService.add({ detail: 'Verification code error' });
      }
    });
  }

  redirect(token: string) {
    if (token) {
      window.location.href = environment.ECOMMERCE_LOGIN_URL + token;
    }
  }

  ngOnDestroy() {
    this.sendCodeSub?.unsubscribe();
    this.verifyCodeSub?.unsubscribe();
    this.timerSubscription?.unsubscribe();
  }
}
