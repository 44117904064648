import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SendSmsCode, VerifyCode } from '../shared/models/api-input.model';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  private apiUrl = environment.TMS_API_URL;

  verifyPhone(
    phoneCode: string,
    phone: string,
    token: string,
    source: string
  ) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/register/verifyPhone`, {
        phoneCode: phoneCode,
        phone: phone,
        token: token,
        source: source
      })
      .pipe(
        map((res) => {
          return res.data;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  sendCode(sendSmsCode: SendSmsCode) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/register/sms/sendCode`, sendSmsCode)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  verifyCode(verifyCode: VerifyCode) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/register/verifyCode`, verifyCode)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  constructor(private http: HttpClient) { }
}
