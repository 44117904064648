import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-login-password',
  templateUrl: './user-login-password.component.html',
  styleUrls: ['./user-login-password.component.scss'],
  providers: [MessageService]
})
export class UserLoginPasswordComponent {
  isLoading = false;
  password: string = '';

  redirect(step: string) {
    this.userService.redirect(step);
  }

  onBackClick() {
    this.userService.redirect('login-home');
  }

  onLoginOtpClick() {
    this.userService.setIsPasswordLogin(false);
    this.redirect('login-captcha');
  }

  onLoginClick() {
    this.isLoading = true;
    this.userService.login({
      phoneCode: this.userService.getPhoneCode(),
      phone: this.userService.getPhone(),
      loginType: 'password',
      code: '',
      password: this.password,
      source: this.userService.getLoginSource()
    }).subscribe(res => {
      if (res.code === 200) {
        location.replace(this.userService.getCallbackUrlWithToken(res.data.token));
      } else {
        this.isLoading = false;
        this.messageService.add({ detail: res.msg || 'Password error' });
      }
    });
  }

  constructor(private userService: UserService, private messageService: MessageService) { }
}
