import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { SignUpProgess } from 'src/app/shared/models/enum.model';
import { validatePhoneNumber } from 'src/app/shared/models/validators.model';

@Component({
  selector: 'app-user-signup-phone-verify-member',
  templateUrl: './user-signup-phone-verify-member.component.html',
  styleUrls: ['./user-signup-phone-verify-member.component.scss']
})
export class UserSignupPhoneVerifyMemberComponent {

  readonly PROGRESS = SignUpProgess;

  phoneRegExp: RegExp = /^\d+$/;
  mobile: string | number;
  phoneCode: string;
  hasPhoneNumber: boolean;

  verifyPhoneSubscription: Subscription | undefined;

  constructor(private userService: UserService) {
    this.mobile = this.userService.getPhone();
    this.phoneCode = this.userService.getPhoneCode();
    this.hasPhoneNumber = this.mobile !== '';
  }

  checkButtonDisabled() {
    if (!validatePhoneNumber(this.mobile)) {
      return true;
    }
    return false;
  }

  onVerifyPhone() {
    this.verifyPhoneSubscription = this.userService.verifyPhone(this.mobile.toString()).subscribe((res: any) => {
      if (res.code === 200) {
        this.userService.setPhoneInfo(this.phoneCode, this.mobile.toString());
        this.userService.redirect('register-captcha');
      } else {
        alert('Phone not verified');
      }
    });
  }

  onBackClick() {
    this.userService.redirect('register-member');
  }

  ngOnDestroy() {
    this.verifyPhoneSubscription?.unsubscribe();
  }
}
