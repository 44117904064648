import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { TimerService } from 'src/app/services/timer.service';
import { UserService } from 'src/app/services/user.service';
import { Countries, Country } from 'src/app/shared/models/country.model';
import { validatePhoneNumber } from 'src/app/shared/models/validators.model';

@Component({
  selector: 'app-user-forgot-password',
  templateUrl: './user-forgot-password.component.html',
  styleUrls: ['./user-forgot-password.component.scss'],
  providers: [MessageService]
})
export class UserForgotPasswordComponent {
  isSelectPhoneCode: boolean = false;

  otpCode: string | number = '';
  phoneCode: string = this.userService.getPhoneCode();
  mobile: string | number = this.userService.getPhone();
  countryCode: string = Countries.filter(country => country.mobileCode === this.phoneCode)[0].code;
  password: string = '';
  phoneRegExp: RegExp = /^\d+$/;
  countdown = 0;

  timerSubscription: Subscription | undefined;
  sendCodeSubscription: Subscription | undefined;
  updatePasswordSubscription: Subscription | undefined;

  redirect(step: string) {
    this.userService.redirect(step);
  }

  onCountrySelected(country: Country) {
    this.phoneCode = country.mobileCode;
    this.countryCode = country.code;
  }

  onBackClick() {
    this.userService.redirect('login-password');
  }

  onSendPasswordOtp() {
    this.sendCodeSubscription = this.userService.sendCodePassword(this.phoneCode, this.mobile.toString()).subscribe((res) => {
      if (res.code === 200) {
        this.messageService.add({
          key: 'success',
          detail: 'SMS have been sent successfully'
        })
        this.startTimer();
      }
    });
  }

  startTimer() {
    this.countdown = 60;
    this.timerSubscription = this.timerService.getCounter().subscribe(() => {
      --this.countdown;
      if (this.countdown === 0) {
        this.stopTimer();
      }
    });
  }

  stopTimer() {
    this.timerSubscription?.unsubscribe();
  }

  checkButtonDisabled() {
    if (!validatePhoneNumber(this.mobile)) {
      return true;
    }

    if (this.otpCode.toString().length !== 6) {
      return true;
    }

    if (this.password.length < 6) {
      return true;
    }

    return false;
  }

  onForgotPassword() {
    this.updatePasswordSubscription = this.userService.updatePassword(
      this.mobile.toString(),
      this.otpCode.toString(),
      this.password).subscribe((res) => {
        if (res.code !== 200) {
          this.messageService.add({ detail: 'Verification code error' });
        }
        if (res.code === 200) {
          this.messageService.add({
            key: 'success',
            detail: 'Password updated successfully'
          })
        }
      });
  }

  constructor(private userService: UserService, private timerService: TimerService, private messageService: MessageService) {
  }

  ngOnDestroy() {
    this.timerSubscription?.unsubscribe();
    this.sendCodeSubscription?.unsubscribe();
    this.updatePasswordSubscription?.unsubscribe();
  }
}
