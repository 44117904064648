import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Countries, Country, PopularCountries } from 'src/app/shared/models/country.model';
@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss']
})
export class CountrySelectComponent {
  @Input() maxHeight:string = '400px';
  @Input() showMobileCode: boolean = true;
  @Output() onCountrySelected = new EventEmitter<Country>();

  countries = Countries;
  popularCountries = PopularCountries;
  searchText: string = '';
  countriesMap = this.groupCountries(this.countries);

  constructor() { }

  onSearchCountry() {
    this.popularCountries = PopularCountries.filter(country => {
      if (this.searchText === '') {
        return true;
      } else {
        return country.name.toLowerCase().includes(this.searchText.toLowerCase());
      }
    });

    this.countries = Countries.filter(country => {
      if (this.searchText === '') {
        return true;
      } else {
        return country.name.toLowerCase().includes(this.searchText.toLowerCase());
      }
    });
    this.countriesMap = this.groupCountries(this.countries);
  }

  groupCountries(countries: Country[]) {
    const map = new Map<string, Country[]>();
    countries.forEach((country) => {
      //A - Z
      for (let i = 65; i <= 90; i++) {
        let alphabet = String.fromCharCode(i);
        const collection = map.get(alphabet);
        if (!collection) {
          if (country.name.startsWith(alphabet)) {
            map.set(alphabet, [country]);
          }
        } else {
          if (country.name.startsWith(alphabet)) {
            collection.push(country);
          }
        }
      }
    });
    return map;
  }

  onCountryClicked(country: Country) {
    this.onCountrySelected.emit(country);
  }
}
