<ng-container *ngIf="isSelectPhoneCode">
    <app-login-header (onBackClick)="this.isSelectPhoneCode = false;"></app-login-header>
    <app-country-select *ngIf="isSelectPhoneCode"
        (onCountrySelected)="onCountrySelected($event);this.isSelectPhoneCode=false;"></app-country-select>
</ng-container>

<ng-container *ngIf="!isSelectPhoneCode">
    <app-login-header (onBackClick)="onBackClick()"></app-login-header>

    <div class="container">
        <div class="mx-auto text-center w-400">
            <h2 class="font-semibold">{{'MyQM.ForgotPassword' | translate}}</h2>

            <div class="mt-8 text-left">
                <label for="mobile" class="text-xs">{{'MyQM.PhoneNumber' | translate}}<span
                        class="text-w300">*</span></label>
                <div class="flex">
                    <!-- Country code and icon -->
                    <div (click)="this.isSelectPhoneCode = true"
                        class="flex mr-2 bg-n30 border-n30 border-round-3xl align-items-center cursor-pointer">
                        <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                            [class]="'flag flag-'+ countryCode.toLowerCase() +' ml-3 mr-2'" style="height: 20px" />
                        <b class="pr-3 white-space-nowrap">+{{ phoneCode }}</b>
                    </div>
                    <div class="flex myqm-field w-full">
                        <input type="tel" pInputText [(ngModel)]="mobile" class="w-full font-bold input-clearable"
                            minlength="4" maxlength="13" [pKeyFilter]="phoneRegExp" />
                        <div [ngClass]="mobile === '' ? 'hidden' : 'flex align-items-center'" class="div-clear-input">
                            <p-button styleClass="border-none shadow-none pl-2 pr-0 btn-clear-input">
                                <img src="./assets/images/common/clear_input.svg" alt="Clear Input" width="15"
                                    (click)="mobile = '';" />
                            </p-button>
                        </div>
                    </div>
                </div>

                <span class="flex p-float-label myqm-field mt-6">
                    <input id="inputOtp" type="text" pInputText [(ngModel)]="otpCode" [pKeyFilter]="'int'"
                        class="w-full font-bold input-clearable" maxlength="6" />
                    <label for="inputOtp">{{'MyQM.LabelVerificationCode' | translate}}</label>
                    <div class="flex align-items-center" class="div-clear-input" style="margin-right: 0rem;">
                        <p-button styleClass="border-none shadow-none pl-2 pr-0 btn-clear-input white-space-nowrap">
                            <span class="text-primary text-sm cursor-pointer" (click)="onSendPasswordOtp()"
                                *ngIf="countdown === 0">{{'MyQM.RequestCode' | translate}}</span>
                            <span class="text-primary text-sm" *ngIf="countdown > 0">{{countdown}} s</span>
                        </p-button>
                    </div>
                </span>

                <span class="p-float-label myqm-field mt-6">
                    <p-password inputId="inputPassword" [feedback]="false" [(ngModel)]="password" styleClass="w-full"
                        inputStyleClass="w-full font-bold">
                    </p-password>
                    <label for="inputPassword">{{'MyQM.LabelNewPassword' | translate}}</label>
                </span>

                <div class="flex flex-column mt-8">
                    <p-button type="submit" [label]="'MyQM.Confirm' | translate" (onClick)="onForgotPassword()"
                        styleClass="btn-custom-primary w-full" [disabled]="checkButtonDisabled()">
                    </p-button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<app-toast-error></app-toast-error>
<p-toast key="success" position="center" [preventOpenDuplicates]="true" styleClass="toast_dark">
    <ng-template let-message pTemplate="message">
        <div class="flex justify-content-center w-full">
            <img src="./assets/images/common/info_circle_gold.svg" class="mr-2 my-auto" height="20" />
            <span class="text-center">{{ message.detail | translate }}</span>
        </div>
    </ng-template>
</p-toast>