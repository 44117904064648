import { Directive, ElementRef, inject, Output } from '@angular/core';
import { filter, fromEvent } from 'rxjs';

@Directive({
  selector: '[appDetectAutofill]',
  standalone: true
})
export class DetectAutofillDirective {

  private elRef: HTMLInputElement = inject(ElementRef<HTMLInputElement>).nativeElement;
  
  // reference: https://blog.stackademic.com/detect-autofill-event-in-angular-like-a-pro-393ac3ed0b95
  @Output()
  public detectAutofill = fromEvent(this.elRef, 'change').pipe(
    filter(() => 
      [':autofill', ':-webkit-autofill'].some((s) => this.elRef.matches(s))
    )
  );

  constructor() { }

}
