<ng-container *ngIf="isSelectPhoneCode">
    <app-delete-user-header (onBackClick)="this.isSelectPhoneCode = false;"></app-delete-user-header>
    <app-country-select *ngIf="isSelectPhoneCode"
        (onCountrySelected)="onCountrySelected($event);this.isSelectPhoneCode=false;"></app-country-select>
</ng-container>

<ng-container *ngIf="!isSelectPhoneCode">
    <app-delete-user-header [showBack]="false"></app-delete-user-header>
    <div class="container">
        <div class="mx-auto text-center w-400">
            <h2 class="font-semibold">{{'MyQM.LabelPhone' | translate}}</h2>
            <div class="mt-8 text-left">
                <label for="mobile" class="text-sm">{{'MyQM.PhoneNumber' | translate}}<span
                        class="text-w300">*</span></label>
                <div class="flex">
                    <!-- Country code and icon -->
                    <div (click)="this.isSelectPhoneCode = true"
                        class="flex mr-2 bg-n30 border-n30 border-round-3xl align-items-center cursor-pointer">
                        <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                            [class]="'flag flag-'+ countryCode.toLowerCase() +' ml-3 mr-2'" style="height: 20px" />
                        <b class="pr-3 white-space-nowrap">+{{ phoneCode }}</b>
                    </div>
                    <div class="flex myqm-field w-full">
                        <input type="tel" pInputText [(ngModel)]="mobile" class="w-full font-bold input-clearable"
                            minlength="4" maxlength="13" [pKeyFilter]="phoneRegExp" />
                        <div [ngClass]="mobile === '' ? 'hidden' : 'flex align-items-center'" class="div-clear-input">
                            <p-button styleClass="border-none shadow-none pl-2 pr-0 btn-clear-input">
                                <img src="./assets/images/common/clear_input.svg" alt="Clear Input" width="15"
                                    (click)="mobile = '';" />
                            </p-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-column align-items-center mt-8">
                <div class="flex align-items-center">
                    <p-checkbox styleClass="p-checkbox-black" [(ngModel)]="ackChecked" [binary]="true"
                        inputId="ack"></p-checkbox>
                    <label for="ack" class="ml-3 text-left">
                        {{'MyQM.AgreeTerms' | translate}}
                        <a target="_blank" [href]="tncUrl" class="text-primary font-semibold no-underline">
                            {{'MyQM.Terms&Conditions' | translate}}
                        </a>
                    </label>
                </div>
            </div>
            <p-button (onClick)="onLoginNext()" type="submit" [label]="'MyQM.Next' | translate"
                styleClass="btn-allow-disable w-full mt-3" [style]="{  
                    'color': 'black',
                }" [disabled]="checkButtonDisabled()">
            </p-button>
        </div>
    </div>
</ng-container>
<app-toast-error></app-toast-error>