import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { DriverService } from 'src/app/services/driver.service';
import { RegisterService } from 'src/app/services/register.service';
import { UserService } from 'src/app/services/user.service';
import { validatePhoneNumber } from 'src/app/shared/models/validators.model';
import { Source } from 'src/app/shared/models/enum.model';

@Component({
  selector: 'app-driver-phone-verify',
  templateUrl: './driver-phone-verify.component.html',
  styleUrls: ['./driver-phone-verify.component.scss']
})
export class DriverPhoneVerifyComponent {

  phoneRegExp: RegExp = /^\d+$/;
  mobile: string | number;
  phoneCode: string;

  verifyPhoneSubscription: Subscription | undefined;

  constructor(
    private driverService: DriverService,
    private userService: UserService,
    private registerService: RegisterService
  ) {
    this.mobile = this.userService.getPhone();
    this.phoneCode = this.userService.getPhoneCode();
  }

  checkButtonDisabled() {
    if (!validatePhoneNumber(this.mobile)) {
      return true;
    }
    return false;
  }

  onVerifyPhone() {
    this.verifyPhoneSubscription = this.registerService.verifyPhone(
      this.phoneCode,
      this.mobile.toString(),
      this.userService.getLoginToken(),
      Source.MMR
    ).subscribe((res) => {
      if (res) {
        this.userService.setPhoneInfo(this.phoneCode, this.mobile.toString());
        this.driverService.redirect('captcha');
      } else {
        alert('Phone not verified');
      }
    });
  }

  ngOnDestroy() {
    this.verifyPhoneSubscription?.unsubscribe();
  }
}
