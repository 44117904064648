<div class="flex flex-column align-items-center mt-6">
  <span class="text-xl font-medium">{{ 'MyQM.LabelVerificationCode' | translate }}</span>
  <span class="mt-2 text-sm">
    {{ 'MyQM.SentOtp' | translate: { phoneCode: phoneCode, mobile: getMaskMobile() } }}
  </span>
</div>

<div class="flex justify-content-center gap-2 mt-6">
  <p-inputNumber #inputOtp [(ngModel)]="otp1" inputStyleClass="otp-field" [maxlength]="1" [min]="0" [max]="9" [tabindex]="1" pAutoFocus [autofocus]="true" (onInput)="onInputOtp($event, 0)" (onKeyDown)="onKeyDownOtp($event, 0)" (onFocus)="onFocus()"></p-inputNumber>
  <p-inputNumber #inputOtp [(ngModel)]="otp2" inputStyleClass="otp-field" [maxlength]="1" [min]="0" [max]="9" [tabindex]="-1" (onInput)="onInputOtp($event, 1)" (onKeyDown)="onKeyDownOtp($event, 1)" (onFocus)="onFocus()"></p-inputNumber>
  <p-inputNumber #inputOtp [(ngModel)]="otp3" inputStyleClass="otp-field" [maxlength]="1" [min]="0" [max]="9" [tabindex]="-1" (onInput)="onInputOtp($event, 2)" (onKeyDown)="onKeyDownOtp($event, 2)" (onFocus)="onFocus()"></p-inputNumber>
  <p-inputNumber #inputOtp [(ngModel)]="otp4" inputStyleClass="otp-field" [maxlength]="1" [min]="0" [max]="9" [tabindex]="-1" (onInput)="onInputOtp($event, 3)" (onKeyDown)="onKeyDownOtp($event, 3)" (onFocus)="onFocus()"></p-inputNumber>
  <p-inputNumber #inputOtp [(ngModel)]="otp5" inputStyleClass="otp-field" [maxlength]="1" [min]="0" [max]="9" [tabindex]="-1" (onInput)="onInputOtp($event, 4)" (onKeyDown)="onKeyDownOtp($event, 4)" (onFocus)="onFocus()"></p-inputNumber>
  <p-inputNumber #inputOtp [(ngModel)]="otp6" inputStyleClass="otp-field" [maxlength]="1" [min]="0" [max]="9" [tabindex]="-1" (onInput)="onInputOtp($event, 5)" (onKeyDown)="onKeyDownOtp($event, 5)" (onFocus)="onFocus()"></p-inputNumber>
</div>

<div class="flex flex-column align-items-center mt-4">
  <span class="text-sm">{{ 'MyQM.NotReceiveOtp' | translate }}</span>
  <span class="text-sm text-p300" *ngIf="countdown > 0">{{ 'MyQM.RequestOtpAgainCountdown' | translate: { countdown: countdown } }}</span>
  <span class="text-sm text-p300 cursor-pointer" (click)="requestNewOtp()" *ngIf="countdown === 0">{{ 'MyQM.RequestOtpAgain' | translate }}</span>
</div>

<app-toast-error></app-toast-error>