import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { IdType } from "./enum.model";
import { Country } from "./country.model";

export function validateFormMobile(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let value = control.value;
        let mobileValue = value.replace(/[^\d]/g, '');

        if (mobileValue.length < 4 || mobileValue.length > 15) {
            return { invalidMobile: true };
        }

        // no error
        return null;
    };
}

// check if the string contains white spaces only
export function validateFormWhitespaceOnly(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let value = control.value;

        if (value !== '' && value.trim().length === 0) {
            return { whitespaceOnly: true };
        }

        // no error
        return null;
    };
}

export function validateFormIdentificationNumber(idType: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (idType === IdType.MYKAD) {
            let ic = control.value.replace(/[^\d]/g, '');
            if (ic.length !== 12) {
                return { invalidId: true };
            }
        } else if (idType === IdType.PASSPORT) {
            let ic = control.value.replace(/[^\d]/g, '');
            if (ic.length < 6 && ic.length > 20) {
                return { invalidId: true };
            }
        }

        // no error 
        return null;
    }
}

export function validateValidCountry(countryList: Country[]) {
    return (control: AbstractControl): ValidationErrors | null => {
        let country = control.value;

        let validCountry = false;
        for (let i = 0; i < countryList.length; i++) {
            if (country === countryList[i].name) {
                validCountry = true;
            }
        }

        if (!validCountry) {
            return { invalidCountry: true };
        }

        // no error 
        return null;
    }
}