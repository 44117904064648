<app-signup-header [progress]="PROGRESS.SIGNUP_HOME" [showProgressBar]="false" (onBackClick)="onBackClick()"></app-signup-header>

<div class="container">
    <div class="mx-auto text-center mt-6 w-400">
        <img src="./assets/images/MyQM Logo.svg" height="90" class="my-6">

        <h2 class="flex flex-wrap font-semibold justify-content-center" [innerHTML]="'MyQM.IsQmMember' | translate">
        </h2>

        <div class="flex flex-column gap-3">
            <p-button (onClick)="isMember()" [label]="'MyQM.Yes' | translate" 
                styleClass="border-round-lg btn-custom-primary w-full"></p-button>
            <p-button (onClick)="isGuest()" [label]="'MyQM.No' | translate"
                styleClass="border-round-lg border-p300 border-2 w-full" [outlined]="true"
                [style]="{'color': 'black'}"></p-button>
        </div>
    </div>
</div>