import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {
  private apiUrl = environment.TMS_API_URL;

  getSlideCode() {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/verification/getSlideCode`, {})
      .pipe(
        map((res) => {
          return res.data;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  checkSlideCode(imageToken: string, x: number, y: number) {
    return this.http
      .post<any>(`${this.apiUrl}/web/v1/verification/checkSlideCode`, {
        imageToken: imageToken,
        x: x.toString(),
        y: y.toString()
      })
      .pipe(
        map((res) => {
          return res.data;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  constructor(private http: HttpClient) { }
}
