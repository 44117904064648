import { Component } from '@angular/core';
import { DriverService } from 'src/app/services/driver.service';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss']
})
export class DriverComponent {
  activeStep$ = this.driverService.step$;

  constructor(private driverService: DriverService, private titleService: Title) {
    this.titleService.setTitle('MyQM Driver Registration');
  }
}
