import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  activeLang: string;
  defaultLang: string = 'en';
  langList: string[] = ['en', 'ms'];

  constructor(
    private translate: TranslateService,
    private config: PrimeNGConfig
  ) {
    this.activeLang = localStorage.getItem('lang')
      ? localStorage.getItem('lang')!
      : this.defaultLang;

    this.translate.addLangs(this.langList);
    this.translate.setDefaultLang(this.activeLang);
    this.translate
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
  }

  getActiveLang() {
    return this.activeLang;
  }

  switchLang(lang: string) {
    if (lang !== this.activeLang) {
      this.activeLang = this.langList.includes(lang) ? lang : 'en';
      localStorage.setItem('lang', this.activeLang);
      window.location.reload();
    }
  }
}
