<app-signup-header [progress]="progress" (onBackClick)="onBackClick()"></app-signup-header>

<div class="container">
  <form [formGroup]="registerForm" (ngSubmit)="register()">
    <div class="flex flex-column mx-auto" style="width: 400px; max-width: 100%">
      <ng-container *ngIf="progress === PROGRESS.SIGNUP_PERSONAL_INFO">
        <div class="flex justify-content-center mt-6">
          <span class="text-xl font-medium">{{ 'MyQM.LabelPersonalDetails' | translate }}</span>
        </div>

        <div class="flex flex-column mt-6">
          <!-- MyKad / passport -->
          <div class="flex p-float-label myqm-field mt-2">
            <input id="inputIdType" type="text" pInputText formControlName="fullName" class="w-full font-bold input-clearable" />
            <label for="inputIdType">{{ 'MyQM.LabelIcFullName' | translate }}*</label>
            <div [ngClass]="registerForm.controls['fullName'].value === '' ? 'hidden' : 'flex align-items-center'" class="div-clear-input">
              <p-button styleClass="border-none shadow-none pl-2 pr-0 btn-clear-input">
                <img src="./assets/images/common/clear_input.svg" width="15" (click)="registerForm.controls['fullName'].setValue('')" />
              </p-button>
            </div>
          </div>
          <div class="flex mt-2" *ngIf="registerForm.controls['fullName'].dirty && registerForm.controls['fullName'].invalid">
            <img src="./assets/images/common/red_warning.svg" class="mr-2 my-auto" height="18" />
            <span class="pr-2 error-msg" style="color: red;" *ngIf="registerForm.controls['fullName'].hasError('required')">{{ 'MyQM.FullNameIsRequired' | translate }}</span>
            <span class="pr-2 error-msg" style="color: red;" *ngIf="registerForm.controls['fullName'].hasError('whitespaceOnly')">{{ 'MyQM.FullNameIsRequired' | translate }}</span>
          </div>

          <!-- Gender -->
          <div class="flex flex-column mt-6">
            <span>{{ 'MyQM.Gender' | translate }}*</span>
            <div class="flex mt-2">
              <div class="flex flex-1 justify-content-start">
                <p-radioButton inputId="genderMale" [value]="GENDER.MALE" formControlName="gender"></p-radioButton>
                <label for="genderMale" class="ml-2">{{ 'MyQM.Male' | translate }}</label>
              </div>
            
              <div class="flex flex-1 justify-content-start">
                <p-radioButton inputId="genderFemale" [value]="GENDER.FEMALE" formControlName="gender"></p-radioButton>
                <label for="genderFemale" class="ml-2">{{ 'MyQM.Female' | translate }}</label>
              </div>
            </div>
          </div>

          <!-- Email -->
          <div class="flex p-float-label myqm-field mt-6">
            <input id="inputEmail" type="text" pInputText formControlName="email" class="w-full font-bold input-clearable" />
            <label for="inputEmail">{{ 'MyQM.EmailAddress' | translate }}*</label>
            <div [ngClass]="registerForm.controls['email'].value === '' ? 'hidden' : 'flex align-items-center'" class="div-clear-input">
              <p-button styleClass="border-none shadow-none pl-2 pr-0 btn-clear-input">
                <img src="./assets/images/common/clear_input.svg" width="15" (click)="registerForm.controls['email'].setValue('')" />
              </p-button>
            </div>
          </div>
          <div class="flex mt-2" *ngIf="registerForm.controls['email'].dirty && registerForm.controls['email'].invalid">
            <img src="./assets/images/common/red_warning.svg" class="mr-2 my-auto" height="18" />
            <span class="pr-2 error-msg" style="color: red;" *ngIf="registerForm.controls['email'].hasError('required')">{{ 'MyQM.EmailAddressIsRequired' | translate }}</span>
            <span class="pr-2 error-msg" style="color: red;" *ngIf="registerForm.controls['email'].hasError('email')">{{ 'MyQM.EmailAddressIncorrect' | translate }}</span>
            <span class="pr-2 error-msg" style="color: red;" *ngIf="registerForm.controls['email'].hasError('whitespaceOnly')">{{ 'MyQM.EmailAddressIsRequired' | translate }}</span>
          </div>

          <!-- Nationality -->
          <div class="flex flex-column mt-6">
            <span>{{ 'MyQM.Nationality' | translate }}*</span>
            <div class="flex flex-column mt-2 gap-2">
              <div class="flex">
                <p-radioButton inputId="malaysian" name="nationality" [value]="NATIONALITY_TYPE.MALAYSIA" [(ngModel)]="nationalityType" [ngModelOptions]="{standalone: true}" (onClick)="setSelectedCountry()"></p-radioButton>
                <label for="malaysian" class="ml-2">{{ 'MyQM.Malaysian' | translate }}</label>
              </div>
            
              <div class="flex">
                <p-radioButton inputId="others" name="nationality" [value]="NATIONALITY_TYPE.OTHERS" [(ngModel)]="nationalityType" [ngModelOptions]="{standalone: true}" (onClick)="setSelectedCountry()"></p-radioButton>
                <div class="myqm-field ml-2 w-full">
                  <p-dropdown 
                    inputId="inputCountry"
                    [options]="COUNTRIES" 
                    placeholder="search country" 
                    [editable]="true" 
                    optionLabel="name"
                    styleClass="w-full"
                    [autoDisplayFirst]="false"
                    [(ngModel)]="selectedCountry"
                    [ngModelOptions]="{standalone: true}"
                    (onChange)="this.nationalityType = NATIONALITY_TYPE.OTHERS; setSelectedCountry()"
                  >
                    <ng-template pTemplate="dropdownicon">
                      <i class="pi pi-search font-bold"></i>
                    </ng-template>
                    <ng-template let-country pTemplate="item">
                      <div class="flex align-items-center gap-2">
                        <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                              [class]="'mr-3 flag flag-' + country.code.toLowerCase()" style="width: 35px" />
                        <div>{{ country.name }}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>  
              </div>
            </div>
          </div>
          <div class="flex mt-2" *ngIf="this.nationalityType !== null && registerForm.controls['nationality'].invalid">
            <img src="./assets/images/common/red_warning.svg" class="mr-2 my-auto" height="18" />
            <span class="pr-2 error-msg" style="color: red;" *ngIf="registerForm.controls['nationality'].hasError('invalidCountry')">{{ 'MyQM.InvalidCountryName' | translate }}</span>
          </div>

          <!-- Identification Type -->
          <div class="flex flex-column mt-6">
            <span>{{ 'MyQM.IdType' | translate }}*</span>
            <div class="flex mt-2">
              <div class="flex flex-1 justify-content-start">
                <p-radioButton inputId="mykad_personal_info" [value]="ID_TYPE.MYKAD" formControlName="idType" (onClick)="setIdType(ID_TYPE.MYKAD)"></p-radioButton>
                <label for="mykad_personal_info" class="ml-2">{{ 'MyQM.MyKad' | translate }}</label>
              </div>

              <div class="flex flex-1 justify-content-start">
                <p-radioButton inputId="passport_personal_info" [value]="ID_TYPE.PASSPORT" formControlName="idType" (onClick)="setIdType(ID_TYPE.PASSPORT)"></p-radioButton>
                <label for="passport_personal_info" class="ml-2">{{ 'MyQM.Passport' | translate }}</label>
              </div>
            </div>
          </div>

          <!-- Identification Number -->
          <div class="flex p-float-label myqm-field mt-6">
            <input #test id="inputIdNumber" type="text" pInputText formControlName="idNumber" class="w-full font-bold input-clearable" (input)="onInput()" (keydown)="setIdNumber($event)" appDetectAutofill (detectAutofill)="onAutoFill()" (paste)="onPaste($event)" maxlength="20" />
            <label for="inputIdNumber">
              <ng-container *ngIf="registerForm.controls['idNumber'].value.trim() === ''">  
                <span>{{ 'MyQM.LabelIdNumber' | translate }}*</span>
              </ng-container>
              <ng-container *ngIf="registerForm.controls['idNumber'].value.trim() !== ''">
                <span>{{ 'MyQM.IdNumber' | translate }}*</span>
              </ng-container>
            </label>
            <div [ngClass]="registerForm.controls['idNumber'].value === '' ? 'hidden' : 'flex align-items-center'" class="div-clear-input">
              <p-button styleClass="border-none shadow-none pl-2 pr-0 btn-clear-input">
                <img src="./assets/images/common/clear_input.svg" width="15" (click)="registerForm.controls['idNumber'].setValue('')" />
              </p-button>
            </div>
          </div>
          <div class="flex mt-2" *ngIf="registerForm.controls['idNumber'].dirty && registerForm.controls['idNumber'].invalid">
            <img src="./assets/images/common/red_warning.svg" class="mr-2 my-auto" height="18" *ngIf="registerForm.controls['idNumber'].hasError('required') || registerForm.controls['idNumber'].hasError('whitespaceOnly')" />
            <span class="pr-2 error-msg" style="color: red;" *ngIf="registerForm.controls['idNumber'].hasError('required')">{{ 'MyQM.IdNumberIsRequired' | translate }}</span>
            <span class="pr-2 error-msg" style="color: red;" *ngIf="registerForm.controls['idNumber'].hasError('whitespaceOnly')">{{ 'MyQM.IdNumberIsRequired' | translate }}</span>
          </div>

          <div class="mt-6">
            <p-button 
                [label]="'MyQM.Continue' | translate"
                styleClass="btn-allow-disable font-medium shadow-2 border-primary"
                [style]="{ 
                  'width': '100%', 
                  'color': 'black',
                }"
                [disabled]="disableContinuePersonalInfo()"
                (onClick)="continue()"
            ></p-button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="progress === PROGRESS.SIGNUP_IDENTIFICATION_INFO">
        <div class="flex justify-content-center mt-6">
          <span class="text-xl font-medium">{{ 'MyQM.UploadIdInfo' | translate }}</span>
        </div>

        <div class="flex flex-column mt-6">
          <!-- Identification Type -->
          <div class="flex flex-column">
            <span>{{ 'MyQM.IdType' | translate }}*</span>
            <div class="flex mt-2">
              <div class="flex flex-1 justify-content-start" *ngIf="info.idType === ID_TYPE.MYKAD">
                <p-radioButton inputId="mykad_id_info" name="id_type" [value]="ID_TYPE.MYKAD" [(ngModel)]="info.idType" [ngModelOptions]="{standalone: true}"></p-radioButton>
                <label for="mykad_id_info" class="ml-2">{{ 'MyQM.MyKad' | translate }}</label>
              </div>

              <div class="flex flex-1 justify-content-start" *ngIf="info.idType === ID_TYPE.PASSPORT">
                <p-radioButton inputId="passport_id_info" name="id_type" [value]="ID_TYPE.PASSPORT" [(ngModel)]="info.idType" [ngModelOptions]="{standalone: true}"></p-radioButton>
                <label for="passport_id_info" class="ml-2">{{ 'MyQM.Passport' | translate }}</label>
              </div>
            </div>
          </div>

          <!-- Identification Number -->
          <div class="flex flex-column mt-6">
            <label for="inputIdNumberDisplay">{{ 'MyQM.IdNumber' | translate }}*</label>
            <input id="inputIdNumberDisplay" type="text" pInputText class="w-full font-bold pl-0 border-none shadow-none opacity-100" [disabled]="true" [value]="registerForm.controls['idNumber'].value" />
          </div>

          <input #imageUpload id="inputImageUpload" type="file" accept=".png" class="hidden" (change)="uploadImage($event)" />
          <ng-container *ngIf="info.idType === ID_TYPE.MYKAD else imageUploadPassport">
            <!-- MyKad Upload -->
            <div class="flex justify-content-center mt-6">
              <span>{{ 'MyQM.MyKadFront' | translate }}</span>
            </div>
            <div class="flex flex-column mx-auto mt-2">
              <ng-container *ngIf="info.mykadFront === '' else uploadedMykadFront">
                <img src="./assets/images/user/id_upload.svg" class="id-upload cursor-pointer" (click)="setUploadType(UPLOAD.MYKAD_FRONT);imageUpload.click();" />
              </ng-container>
              <ng-template #uploadedMykadFront>
                <img [src]="API_URL + info.mykadFront" class="id-upload" />
                <div class="flex justify-content-center reupload-btn cursor-pointer" (click)="setUploadType(UPLOAD.MYKAD_FRONT);imageUpload.click();"><span>{{ 'MyQM.Reupload' | translate }}</span></div>
              </ng-template>
            </div>

            <div class="flex justify-content-center mt-6">
              <span>{{ 'MyQM.MyKadBack' | translate }}</span>
            </div>
            <div class="flex flex-column mx-auto mt-2">
              <ng-container *ngIf="info.mykadBack === '' else uploadedMykadBack">
                <img src="./assets/images/user/id_upload.svg" class="id-upload cursor-pointer" (click)="setUploadType(UPLOAD.MYKAD_BACK);imageUpload.click();" />
              </ng-container>
              <ng-template #uploadedMykadBack>
                <img [src]="API_URL + info.mykadBack" class="id-upload" />
                <div class="flex justify-content-center reupload-btn cursor-pointer" (click)="setUploadType(UPLOAD.MYKAD_BACK);imageUpload.click();"><span>{{ 'MyQM.Reupload' | translate }}</span></div>
              </ng-template>
            </div>
          </ng-container>
          <ng-template #imageUploadPassport>
            <!-- Passport Upload -->
            <div class="flex justify-content-center mt-6">
              <span>{{ 'MyQM.PassportFront' | translate }}</span>
            </div>
            <div class="flex flex-column mx-auto mt-2">
              <ng-container *ngIf="info.passport === '' else uploadedPassport">
                <img src="./assets/images/user/id_upload.svg" class="id-upload cursor-pointer" (click)="setUploadType(UPLOAD.PASSPORT);imageUpload.click();" />
              </ng-container>
              <ng-template #uploadedPassport>
                <img [src]="API_URL + info.passport" class="id-upload" />
                <div class="flex justify-content-center reupload-btn cursor-pointer" (click)="setUploadType(UPLOAD.PASSPORT);imageUpload.click();"><span>{{ 'MyQM.Reupload' | translate }}</span></div>
              </ng-template>
            </div>
          </ng-template>

          <!-- Profile Upload -->
          <div class="flex justify-content-center mt-6">
            <span>{{ 'MyQM.ProfilePicture' | translate }}</span>
          </div>
          <div class="flex flex-column mx-auto mt-2">
            <ng-container *ngIf="info.profilePicture === '' else uploadedProfilePic">
              <img src="./assets/images/user/profile_upload.svg" class="profile-upload cursor-pointer" (click)="setUploadType(UPLOAD.PROFILE_PIC);imageUpload.click();" />
            </ng-container>
            <ng-template #uploadedProfilePic>
              <img [src]="API_URL + info.profilePicture" class="profile-upload" />
              <div class="flex justify-content-center reupload-btn cursor-pointer" (click)="setUploadType(UPLOAD.PROFILE_PIC);imageUpload.click();"><span>{{ 'MyQM.Reupload' | translate }}</span></div>
            </ng-template>
          </div>
        </div>

        <!-- Requirement Description -->
        <div class="flex flex-column mt-6 mx-auto" style="width: 400px;">
          <span class="font-italic text-n100">{{ 'MyQM.Requirement' | translate }}</span>
          <ul class="list-disc font-italic text-n100 m-0" style="padding-left: 24px;">
            <li>{{ 'MyQM.ProfilePictureRequirement1' | translate }}</li>
            <li>{{ 'MyQM.ProfilePictureRequirement2' | translate }}</li>
            <li>{{ 'MyQM.ProfilePictureRequirement3' | translate }}</li>
          </ul>
        </div>

        <div class="flex flex-column mt-6">
          <!-- Temporary hide referral code page, replace current buttons with commented buttons to show referral code page again -->
          <!--p-button 
              [label]="'MyQM.Continue' | translate"
              styleClass="btn-allow-disable font-medium shadow-2 border-primary"
              [style]="{ 
                'width': '100%', 
                'color': 'black',
              }"
              [disabled]="disableContinueIdInfo"
              (onClick)="continue()"
          ></p-button>
          <p-button
              [label]="'MyQM.MaybeLater' | translate"
              styleClass="bg-white mt-3 w-full font-medium shadow-2 border-primary"
              [style]="{
                'color': 'black'
              }"
              (onClick)="continue()"
          ></p-button-->
          <p-button 
              type="submit"
              [label]="'MyQM.Confirm' | translate"
              styleClass="btn-allow-disable font-medium shadow-2 border-primary"
              [style]="{ 
                'width': '100%', 
                'color': 'black',
              }"
              [disabled]="disableContinueIdInfo"
          ></p-button>
          <p-button
              type="submit"
              [label]="'MyQM.MaybeLater' | translate"
              styleClass="bg-white mt-3 w-full font-medium shadow-2 border-primary"
              [style]="{
                'color': 'black'
              }"
              *ngIf="!userService.getIsReferralLink()"
          ></p-button>
        </div>
      </ng-container>

      <ng-container *ngIf="progress === PROGRESS.SIGNUP_REFERRAL">
        <div class="flex justify-content-center mt-6">
          <span class="text-xl font-medium">{{ 'MyQM.LabelReferralCode' | translate }}</span>
        </div>

        <div class="flex flex-column mt-6">
          <span>{{ 'MyQM.ReferralCodeDescription' | translate }}</span>
        </div>

        <div class="flex p-float-label myqm-field mt-6">
          <input id="inputReferralCode" type="text" pInputText formControlName="referralCode" class="w-full font-bold input-clearable" [ngClass]="userService.getIsReferralLink() ? 'opacity-100' : ''"
            (focus)="labelReferralCode.innerHTML =  getReferralCodeText('MyQM.ReferralCode')" 
            (blur)="registerForm.controls['referralCode'].value === '' ? labelReferralCode.innerHTML = getReferralCodeText('MyQM.EnterReferralCode') : ''"
          />
          <label #labelReferralCode for="inputReferralCode">{{ userService.getIsReferralLink() ? getReferralCodeText('MyQM.ReferralCode') : getReferralCodeText('MyQM.EnterReferralCode') }}</label>
          <div [ngClass]="registerForm.controls['referralCode'].value === '' || userService.getIsReferralLink() ? 'hidden' : 'flex align-items-center'" class="div-clear-input">
            <p-button styleClass="border-none shadow-none pl-2 pr-0 btn-clear-input">
              <img src="./assets/images/common/clear_input.svg" width="15" 
                (click)="registerForm.controls['referralCode'].setValue('');
                labelReferralCode.innerHTML = getReferralCodeText('MyQM.EnterReferralCode')" 
              />
            </p-button>
          </div>
        </div>

        <div class="flex flex-column mt-6">
          <p-button 
              type="submit"
              [label]="'MyQM.Confirm' | translate"
              styleClass="btn-allow-disable font-medium shadow-2 border-primary"
              [style]="{ 
                'width': '100%', 
                'color': 'black',
              }"
              [disabled]="registerForm.controls['referralCode'].value.trim() === ''"
          ></p-button>
          <p-button
              type="submit"
              [label]="'MyQM.MaybeLater' | translate"
              styleClass="bg-white mt-3 w-full font-medium shadow-2 border-primary"
              [style]="{
                'color': 'black'
              }"
              *ngIf="!userService.getIsReferralLink()"
          ></p-button>
        </div>
      </ng-container>
    </div>
  </form>
</div>