<div class="container">
  <div class="flex flex-column text-center mt-6">
      <h2 class="font-semibold my-0">{{'MyQM.VerifyPhoneTitle' | translate}}</h2>
      <h3 class="my-0 font-normal" [innerHTML]="'MyQM.VerifyPhoneSubtitle' | translate"></h3>

      <span class="mt-6 text-sm">
          {{'MyQM.SendVerification' | translate}}
      </span>
  </div>

  <div class="mx-auto" style="width: 400px; max-width: 100%">
      <div class="mt-6">
          <label for="inputMobile" class="text-sm">{{'MyQM.PhoneNumber' | translate}}<span class="text-w300">*</span></label>
          <div class="flex">
              <!-- Country code and icon -->
              <div class="flex mr-2 bg-n30 border-n30 border-round-3xl align-items-center">
                  <img src="./assets/images/common/national_flag.svg" alt="Malaysia" height="20" class="pl-3 pr-2" />
                  <b class="pr-3">+{{ phoneCode }}</b>
              </div>
              <div class="flex myqm-field w-full">
                  <input id="inputMobile" type="tel" pInputText [(ngModel)]="mobile" class="w-full font-bold input-clearable"
                      [placeholder]="'MyQM.LabelPhone' | translate" minlength="4" maxlength="13" [pKeyFilter]="phoneRegExp" />
                  <div [ngClass]="mobile === '' ? 'hidden' : 'flex align-items-center'" class="div-clear-input">
                      <p-button styleClass="border-none shadow-none pl-2 pr-0 btn-clear-input">
                          <img src="./assets/images/common/clear_input.svg" alt="Clear Input" width="15"
                              (click)="mobile = '';" />
                      </p-button>
                  </div>
              </div>
          </div>
      </div>

      <div class="flex flex-column gap-3 mt-6">
          <p-button [label]="'MyQM.Next' | translate" (onClick)="onVerifyPhone()" styleClass="btn-allow-disable" [style]="{ 
                  'width': '100%', 
                  'color': 'black',
              }" [disabled]="checkButtonDisabled()">
          </p-button>
      </div>
  </div>
</div>