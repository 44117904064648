<div class="container">
    <div class="mx-auto text-center mt-6 w-450">
        <h1 class="font-semibold">{{'MyQM.DeleteAccount' | translate}}</h1>

        <img src="./assets/images/common/success.svg" height="180" class="my-4">

        <div class="pt-2">
            <span class="text-2xl font-semibold">{{'MyQM.SubmitSuccess' | translate}}</span>
        </div>
    </div>
</div>