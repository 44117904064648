import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LocationStrategy } from '@angular/common';
import { HeaderComponent } from './components/common/header/header.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { LandingComponent } from './components/pages/landing/landing.component';
import { DriverComponent } from './components/pages/driver/driver.component';
import { SellerComponent } from './components/pages/seller/seller.component';
import { DriverLoginComponent } from './components/pages/driver-login/driver-login.component';
import { SellerLoginComponent } from './components/pages/seller-login/seller-login.component';
import { DriverCaptchaComponent } from './components/pages/driver-captcha/driver-captcha.component';
import { DriverPhoneVerifyComponent } from './components/pages/driver-phone-verify/driver-phone-verify.component';
import { DriverOtpComponent } from './components/pages/driver-otp/driver-otp.component';
import { MessageService } from 'primeng/api';
import { MyqmInterceptor } from './core/interceptors/myqm.interceptor';
import { SellerCaptchaComponent } from './components/pages/seller-captcha/seller-captcha.component';
import { SellerOtpComponent } from './components/pages/seller-otp/seller-otp.component';
import { SellerPhoneVerifyComponent } from './components/pages/seller-phone-verify/seller-phone-verify.component';
import { CaptchaComponent } from './components/common/captcha/captcha.component';
import { UserComponent } from './components/pages/user/user.component';
import { UserHomeComponent } from './components/pages/user-home/user-home.component';
import { UserLoginComponent } from './components/pages/user-login/user-login.component';
import { OtpComponent } from './components/common/otp/otp.component';
import { ToastErrorComponent } from './components/common/toast-error/toast-error.component';
import { UserLoginCaptchaComponent } from './components/pages/user-login-captcha/user-login-captcha.component';
import { UserSignupHomeComponent } from './components/pages/user-signup-home/user-signup-home.component';
import { UserSignupMemberComponent } from './components/pages/user-signup-member/user-signup-member.component';
import { UserSignupGuestComponent } from './components/pages/user-signup-guest/user-signup-guest.component';
import { UserSignupCaptchaComponent } from './components/pages/user-signup-captcha/user-signup-captcha.component';
import { UserLoginOtpComponent } from './components/pages/user-login-otp/user-login-otp.component';
import { UserSignupPhoneVerifyMemberComponent } from './components/pages/user-signup-phone-verify-member/user-signup-phone-verify-member.component';
import { UserLoginHomeComponent } from './components/pages/user-login-home/user-login-home.component';
import { UserSignupComponent } from './components/pages/user-signup/user-signup.component';
import { SignupHeaderComponent } from './components/common/signup-header/signup-header.component';
import { UserSignupOtpComponent } from './components/pages/user-signup-otp/user-signup-otp.component';
import { UserLoginPasswordComponent } from './components/pages/user-login-password/user-login-password.component';
import { UserSignupPersonalInfoComponent } from './components/pages/user-signup-personal-info/user-signup-personal-info.component';
import { CountrySelectComponent } from './components/common/country-select/country-select.component';
import { LoginHeaderComponent } from './components/common/login-header/login-header.component';
import { LoadingSpinnerComponent } from './components/common/loading-spinner/loading-spinner.component';
import { UserForgotPasswordComponent } from './components/pages/user-forgot-password/user-forgot-password.component';
import { DeleteComponent } from './components/pages/delete/delete.component';
import { DeleteUserComponent } from './components/pages/delete-user/delete-user.component';
import { DeleteDriverComponent } from './components/pages/delete-driver/delete-driver.component';
import { DeleteUserLoginComponent } from './components/pages/delete-user-login/delete-user-login.component';
import { DeleteUserCaptchaComponent } from './components/pages/delete-user-captcha/delete-user-captcha.component';
import { DeleteUserLoginOtpComponent } from './components/pages/delete-user-login-otp/delete-user-login-otp.component';
import { DeleteUserLoginPasswordComponent } from './components/pages/delete-user-login-password/delete-user-login-password.component';
import { DeleteUserConfirmComponent } from './components/pages/delete-user-confirm/delete-user-confirm.component';
import { DeleteUserHeaderComponent } from './components/common/delete-user-header/delete-user-header.component';
import { DeleteDriverLoginComponent } from './components/pages/delete-driver-login/delete-driver-login.component';
import { DeleteDriverConfirmComponent } from './components/pages/delete-driver-confirm/delete-driver-confirm.component';
import { DeleteDriverHeaderComponent } from './components/common/delete-driver-header/delete-driver-header.component';
import { DeleteDriverSuccessComponent } from './components/pages/delete-driver-success/delete-driver-success.component';
import { DeleteUserSuccessComponent } from './components/pages/delete-user-success/delete-user-success.component';
import { UserSignupPhoneRegisteredComponent } from './components/pages/user-signup-phone-registered/user-signup-phone-registered.component';
import { UserSignupCompleteComponent } from './components/pages/user-signup-complete/user-signup-complete.component';
import { SellerRedirectComponent } from './components/pages/seller-redirect/seller-redirect.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LandingComponent,
    DriverComponent,
    SellerComponent,
    DriverLoginComponent,
    SellerLoginComponent,
    DriverCaptchaComponent,
    DriverPhoneVerifyComponent,
    DriverOtpComponent,
    SellerCaptchaComponent,
    SellerOtpComponent,
    SellerPhoneVerifyComponent,
    CaptchaComponent,
    UserComponent,
    UserHomeComponent,
    UserLoginComponent,
    OtpComponent,
    ToastErrorComponent,
    UserLoginCaptchaComponent,
    UserSignupHomeComponent,
    UserSignupMemberComponent,
    UserSignupGuestComponent,
    UserSignupCaptchaComponent,
    UserLoginOtpComponent,
    UserSignupPhoneVerifyMemberComponent,
    UserLoginHomeComponent,
    UserSignupComponent,
    SignupHeaderComponent,
    UserSignupOtpComponent,
    UserLoginPasswordComponent,
    UserSignupPersonalInfoComponent,
    CountrySelectComponent,
    LoginHeaderComponent,
    LoadingSpinnerComponent,
    UserForgotPasswordComponent,
    DeleteComponent,
    DeleteUserComponent,
    DeleteDriverComponent,
    DeleteUserLoginComponent,
    DeleteUserCaptchaComponent,
    DeleteUserLoginOtpComponent,
    DeleteUserLoginPasswordComponent,
    DeleteUserConfirmComponent,
    DeleteUserHeaderComponent,
    DeleteDriverLoginComponent,
    DeleteDriverConfirmComponent,
    DeleteDriverHeaderComponent,
    DeleteDriverSuccessComponent,
    DeleteUserSuccessComponent,
    UserSignupPhoneRegisteredComponent,
    UserSignupCompleteComponent,
    SellerRedirectComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, LocationStrategy]
      }
    })
  ],
  providers: [
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: MyqmInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function HttpLoaderFactory(
  http: HttpClient,
  locationStrategy: LocationStrategy
) {
  return new TranslateHttpLoader(
    http,
    locationStrategy.getBaseHref() + './assets/i18n/',
    '.json'
  );
}
