<div class="container">
  <div class="mt-6 flex justify-content-center">
    <img src="./assets/images/common/success_background.svg" />
  </div>
  <div class="mt-4 flex justify-content-center">
    <b>{{ 'MyQM.SignUpComplete' | translate }}</b>
  </div>
  <div class="mt-6 flex justify-content-center">
    <p-button [label]="'MyQM.DownloadAndStartNow' | translate" styleClass="border-round-lg w-full font-medium shadow-2 outline-none" [style]="{'color': 'black'}" (click)="download()"></p-button>
  </div>
  <div class="mt-4 flex justify-content-center">
    <span>{{ 'MyQM.ReferralCode' }}: {{ getReferralCode() }}</span>
  </div>
</div>