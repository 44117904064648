<div class="container">
    <div class="mx-auto mt-6 text-center w-400">

        <div class="relative ">
            <div (click)="back()"
                class="bg-sg100 border-circle absolute top-0 left-0 cursor-pointer mt-2 flex justify-content-center align-items-center"
                style="width: 35px; height: 35px;">
                <span class="pi pi-chevron-left"></span>
            </div>
            <h1 class="font-semibold">{{'MyQM.Login' | translate}}</h1>
        </div>

        <img src="./assets/images/MyQM Logo.svg" height="90" class="my-4">
    </div>
</div>