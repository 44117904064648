<div class="mx-auto mt-6 w-400" style="min-height: 300px;">
    <div *ngIf="imgBackgroundSrc && imgSlideSrc" class="bg-n20 shadow-3 border-round-2xl" style="padding: 14px;">
        <div class="mx-auto relative text-center" style="max-width: 345px;">
            <img [src]="imgBackgroundSrc" class="border-round-2xl" [style]="{
                    'width': oriBackgroundWidth * captchaScale + 'px'
                }">
            <br>
            <span class="text-sm">{{'MyQM.SlideInstruction' | translate}}</span>
            
            <img [src]="imgSlideSrc" class="absolute" [style]="{
                    'width': oriSlideWidth * captchaScale + 'px',
                    'top': positionY * captchaScale + 'px', 
                    'left': positionX * captchaScale + 'px'
                }">

            <img *ngIf="isLoadingCaptcha" src="./assets/images/captcha/loading.gif" width="70" class="absolute"
                style="top:80px;left:50%;transform: translate(-50%, 0);">

            <input class="w-full my-4" type="range" #slider [value]="positionX" min="0"
                (input)="onSlide(slider.value)" (change)="onCompleteSlide(slider.value)" max="278"
                onkeydown="return false;" [disabled]="isCaptchaVerified">

            <div class="flex" *ngIf="!isCaptchaVerified">
                <div class="ml-auto">
                    <i class="pb-2 pi pi-refresh text-xl text-500 cursor-pointer"
                        (click)="onResetCapchaImage()"></i>
                </div>
            </div>
        </div>
    </div>
</div>