import { Component } from '@angular/core';
import { DriverService } from 'src/app/services/driver.service';

@Component({
  selector: 'app-driver-captcha',
  templateUrl: './driver-captcha.component.html',
  styleUrls: ['./driver-captcha.component.scss']
})
export class DriverCaptchaComponent {
  isCaptchaVerified: boolean = false;

  constructor(private driverService: DriverService) {
  }

  onVerifyCaptcha(verified: boolean) {
    this.isCaptchaVerified = verified;
  }

  redirectToOtp() {
    this.driverService.redirect('otp');
  }
}
