import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Source } from 'src/app/shared/models/enum.model';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss']
})
export class UserHomeComponent {
  redirect(step: string) {
    this.userService.redirect(step);
  }

  constructor(private userService: UserService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.userService.setCallbackUrl(params['callbackUrl'] || '');
      this.userService.setLoginSource(params['source'] || Source.QMI);
    })
  }
}
