import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SignUpProgess } from 'src/app/shared/models/enum.model';

@Component({
  selector: 'app-user-signup-home',
  templateUrl: './user-signup-home.component.html',
  styleUrls: ['./user-signup-home.component.scss']
})
export class UserSignupHomeComponent {
  
  readonly PROGRESS = SignUpProgess;

  redirect(step: string) {
    this.userService.redirect(step);
  }

  isMember() {
    this.userService.setIsQmMember(true);
    this.redirect('register-member');
  }

  isGuest() {
    this.userService.setIsQmMember(false);
    this.redirect('register-guest');
  }

  onBackClick() {
    this.router.navigate(['..'], {
      relativeTo: this.route,
      queryParams: {
        callbackUrl: this.userService.getCallbackUrl()
      },
      replaceUrl: true
    });
  }

  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute) { 
    this.isGuest();
  }
}
