import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { VerificationService } from 'src/app/services/verification.service';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent {
  @Output() onVerifyCaptcha = new EventEmitter<boolean>();

  oriBackgroundWidth: number = 345;
  oriSlideWidth: number = 71;

  imgBackgroundSrc: string | undefined;
  imgSlideSrc: string | undefined;

  captchaScale: number = 1;
  positionY: number = 0;
  positionX: number = 0;
  imageToken: string = '';

  isLoadingCaptcha = false;
  isCaptchaVerified = false;

  getSlideCodeSubscription: Subscription | undefined;
  checkSlideCodeSubscription: Subscription | undefined;

  constructor(private verificationService: VerificationService, private userService: UserService) {
    this.setCaptchaScale();
  }

  ngOnInit() {
    this.getCaptchaImages();
  }

  setCaptchaScale() {
    if (window.innerWidth > 420) {
      this.captchaScale = 1;
    } else {
      let pictureWidth = window.innerWidth - 28 - 30; // 23 - div padding, 30 - container padding
      this.captchaScale = pictureWidth / this.oriBackgroundWidth;
    }
  }

  onSlide(x: string) {
    this.positionX = parseInt(x);
  }

  onCompleteSlide(x: string) {
    this.verifyCaptcha();
  }

  getCaptchaImages() {
    this.isLoadingCaptcha = true;
    this.getSlideCodeSubscription = this.verificationService.getSlideCode().subscribe((res) => {
      this.imageToken = res.imageToken;
      this.imgBackgroundSrc = res.backImage;
      this.imgSlideSrc = res.slideImage;
      this.positionY = parseInt(res.y);
      this.positionX = 0;

      this.isLoadingCaptcha = false;
    })
  }

  verifyCaptcha() {
    this.verificationService.checkSlideCode(this.imageToken, this.positionX, this.positionY).subscribe((res) => {
      if (res) {
        this.isCaptchaVerified = true;
        this.userService.setCaptchaInfo(this.imageToken, this.positionX, this.positionY);
      } else {
        this.isCaptchaVerified = false;
        this.getCaptchaImages();
      }
      this.onVerifyCaptcha.emit(this.isCaptchaVerified);
    })
  }

  onResetCapchaImage() {
    this.getCaptchaImages();
  }

  /*
  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(innerWidth: number) {
    if (innerWidth <= 420) {
      this.setCaptchaScale();
    }
  }
  */
 
  ngOnDestroy() {
    this.getSlideCodeSubscription?.unsubscribe();
    this.checkSlideCodeSubscription?.unsubscribe();
  }
}
