<app-delete-user-header (onBackClick)="onBackClick()"></app-delete-user-header>

<div class="container">
    <div class="mx-auto text-center w-400">
        <h2 class="font-semibold">{{'MyQM.LoginWithPassword' | translate}}</h2>

        <span class="p-float-label myqm-field mt-8">
            <p-password inputId="inputPassword" [(ngModel)]="password" [toggleMask]="true" [feedback]="false"
                styleClass="w-full" inputStyleClass="w-full font-bold" [maxLength]="16">
                <ng-template pTemplate="showicon">
                    <div class="flex h-full py-auto px-2 cursor-pointer">
                        <img src="./assets/images/common/eye_closed.svg" alt="Show Password" style="width: 25px;" />
                    </div>
                </ng-template>
                <ng-template pTemplate="hideicon">
                    <div class="flex h-full py-auto px-2 cursor-pointer">
                        <img src="./assets/images/common/eye_open.svg" alt="Hide Password" style="width: 25px;" />
                    </div>
                </ng-template>
            </p-password>
            <label for="inputPassword">{{'MyQM.LabelPasswordDigit' | translate}}</label>
        </span>

        <div class="flex flex-column mt-8">
            <p-button type="submit" [label]="'MyQM.Confirm' | translate" (onClick)="onLoginClick()" styleClass="btn-allow-disable" [style]="{ 
                    'width': '100%', 
                    'color': 'black',
                }" [disabled]="this.password.length < 6">
            </p-button>
        </div>

        <div class="flex my-5 align-items-center">
            <div class="flex-1 border-top-1 border-n200"></div>
            <span class="mx-2">{{'MyQM.Or' | translate}}</span>
            <div class="flex-1 border-top-1 border-n200"></div>
        </div>

        <div class="flex flex-column">
            <p-button (onClick)="onLoginOtpClick()" type="submit" [label]="'MyQM.LoginWithVerification' | translate"
                styleClass="border-p300 border-2" [style]="{ 
                    'width': '100%', 
                    'color': 'black',
                }" [outlined]="true">
            </p-button>
        </div>
    </div>
</div>
<app-toast-error></app-toast-error>