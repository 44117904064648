<app-login-header (onBackClick)="onBackClick()"></app-login-header>

<div class="container">
    <div class="mx-auto text-center w-400">
        <!-- Otp entered here -->
        <app-otp [countdown]="countdown" (verifyCodeEvent)="verifyCode($event)"
            (requestNewOtpEvent)="requestNewOtp($event)"></app-otp>

        <ng-container *ngIf="hasPasswordLogin">
            <div class="flex my-5 align-items-center">
                <div class="flex-1 border-top-1 border-n200"></div>
                <span class="mx-2">{{'MyQM.Or' | translate}}</span>
                <div class="flex-1 border-top-1 border-n200"></div>
            </div>

            <div class="flex flex-column">
                <p-button (onClick)="redirect('login-password')" type="submit" [label]="'MyQM.LoginWithPassword' | translate"
                    styleClass="border-p300 border-2" [style]="{ 
                        'width': '100%', 
                        'color': 'black',
                    }" [outlined]="true">
                </p-button>
            </div>
        </ng-container>
    </div>
</div>