import { IdType } from "./enum.model";

// API: /web/v1/qm/member/login
export class LoginInfo {
    loginType: string;  // enum: LoginType
    phoneCode: string;  // enum: PhoneCode, optional field
    loginId: string;
    password: string;

    constructor(loginType: string, phoneCode: string, loginId: string, password: string) {
        this.loginType = loginType;
        this.phoneCode = phoneCode;
        this.loginId = loginId;
        this.password = password;
    }
}

// API: /web/v1/register/sms/sendCode
export class SendSmsCode {
    imageToken: string;
    x: string;
    y: string;
    phoneCode: string;  // enum: PhoneCode
    phone: string;
    source: string; // enum: Source

    constructor(imageToken: string, x: string, y: string, phoneCode: string, phone: string, source: string) {
        this.imageToken = imageToken;
        this.x = x;
        this.y = y;
        this.phoneCode = phoneCode;
        this.phone = phone;
        this.source = source;
    }
}

// API: /web/v1/user/sms/sendCode
export class SendUserSmsCode {
    imageToken: string;
    x: string;
    y: string;
    phoneCode: string;  // enum: PhoneCode
    phone: string;

    constructor(imageToken: string, x: string, y: string, phoneCode: string, phone: string) {
        this.imageToken = imageToken;
        this.x = x;
        this.y = y;
        this.phoneCode = phoneCode;
        this.phone = phone;
    }
}

// API: /web/v1/register/verifyCode
export class VerifyCode {
    phone: string;
    code: string;
    token: string;
    source: string;

    constructor(phone: string, code: string, token: string, source: string) {
        this.phone = phone;
        this.code = code;
        this.token = token;
        this.source = source;
    }
}

// API: /web/v1/user/sms/verifyCode
export class VerifyUserCode {
    phone: string;
    code: string;

    constructor(phone: string, code: string) {
        this.phone = phone;
        this.code = code;
    }
}

/**
 * START - API: /web/v1/user/register
 */
export class RegistrationInfo {
    phoneCode: string;
    phone: string;
    code: string;
    fullName: string;
    gender: string;         // enum: Gender
    email: string;
    nationality: string;
    idType: string;         // enum: IdType
    idNumber: string;
    mykadFront: string;     // optional
    mykadBack: string;      // optional
    profilePicture: string; // optional
    referralCode: string;   // optional

    constructor(
        phoneCode: string, 
        phone: string, 
        code: string, 
        fullName: string, 
        gender: string, 
        email: string,
        nationality: string,
        idType: string,
        idNumber: string,
        mykadFront: string,
        mykadBack: string,
        profilePicture: string,
        referralCode: string
    ) {
        this.phoneCode = phoneCode;
        this.phone = phone;
        this.code = code;
        this.fullName = fullName;
        this.gender = gender;
        this.email = email;
        this.nationality = nationality;
        this.idType = idType;
        this.idNumber = idNumber;
        this.mykadFront = mykadFront;
        this.mykadBack = mykadBack;
        this.profilePicture = profilePicture;
        this.referralCode = referralCode;
    }
}

export class RegistrationInfoWorkingObject {
    phoneCode: string;
    phone: string;
    code: string;               // otp
    fullName: string;
    gender: string;             // enum: Gender
    email: string;
    nationality: string;
    idType: string;             // enum: IdType
    idNumber: string;
    mykadFront: string;         // optional
    mykadBack: string;          // optional
    passport: string;           // optional
    profilePicture: string;     // optional
    referralCode: string;       // optional

    constructor() {
        this.phoneCode = '';
        this.phone = '';
        this.code = '';
        this.fullName = '';
        this.gender = '';
        this.email = '';
        this.nationality = '';
        this.idType = '';
        this.idNumber = '';
        this.mykadFront = '';
        this.mykadBack = '';
        this.passport = '';
        this.profilePicture = '';
        this.referralCode = '';
    }
}
/**
 * END - API: /web/v1/user/register
 */

// API: /web/v1/user/login
export interface UserLoginInfo {
    phoneCode: string;
    phone: string;
    loginType: string;
    code: string;
    password: string;
    source: string; // mall/qmi
}
