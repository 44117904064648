import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-signup-complete',
  templateUrl: './user-signup-complete.component.html',
  styleUrls: ['./user-signup-complete.component.scss']
})
export class UserSignupCompleteComponent {

  constructor(private userService: UserService) {

  }

  download() {
    window.location.href = environment.APP_DOWNLOAD_URL;
  }

  getReferralCode() {
    return this.userService.getReferralCode();
  }
}