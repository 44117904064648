import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SellerService {
  private stepSubject: BehaviorSubject<string>;
  public step$: Observable<string>;

  constructor() {
    //this.stepSubject = new BehaviorSubject<string>('login');
    this.stepSubject = new BehaviorSubject<string>('redirect'); //temporary landing page for redirect
    this.step$ = this.stepSubject.asObservable();
  }

  redirect(step: string) {
    this.stepSubject.next(step);
  }
}
