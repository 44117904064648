import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  constructor(private languageService: LanguageService, private route: ActivatedRoute) {
    //For language handling
    let language = this.route.snapshot.queryParamMap.get('lang') || this.languageService.getActiveLang();
    this.languageService.switchLang(language);
  }

  ngOnInit() { }
}
