import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { SignUpProgess } from 'src/app/shared/models/enum.model';

@Component({
  selector: 'app-user-signup-captcha',
  templateUrl: './user-signup-captcha.component.html',
  styleUrls: ['./user-signup-captcha.component.scss']
})
export class UserSignupCaptchaComponent {
  
  readonly PROGRESS = SignUpProgess;

  isCaptchaVerified: boolean = false;

  constructor(private userService: UserService) {
  }

  onVerifyCaptcha(verified: boolean) {
    this.isCaptchaVerified = verified;
  }

  redirectToOtp() {
    this.userService.redirect('register-otp');
  }

  onBackClick() {
    if (this.userService.getIsQmMember() === true) {
      this.userService.redirect('register-phone-verify-member');
    } else {
      this.userService.redirect('register-guest');
    }
  }
}
