import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { QmMemberService } from 'src/app/services/qm-member.service';
import { UserService } from 'src/app/services/user.service';
import { LoginInfo } from 'src/app/shared/models/api-input.model';
import { Country } from 'src/app/shared/models/country.model';
import { LoginType, PhoneCode, SignUpProgess } from 'src/app/shared/models/enum.model';
import { validateFormWhitespaceOnly, validateFormMobile } from 'src/app/shared/models/form-validators.model';

@Component({
  selector: 'app-user-signup-member',
  templateUrl: './user-signup-member.component.html',
  styleUrls: ['./user-signup-member.component.scss'],
  providers: [MessageService]
})
export class UserSignupMemberComponent {

  // enum
  readonly LOGIN_TYPE = LoginType;
  readonly PROGRESS = SignUpProgess;

  // constant
  readonly PHONE_REG_EXP: RegExp = /^\d+$/;

  selectedMethod: string = LoginType.EMAIL;

  email: string = '';
  mobile: string | number = '';
  username: string = '';
  password: string = '';

  // select country code related variables
  isSelectPhoneCode: boolean = false;
  phoneCode: string = PhoneCode.MYS;
  countryCode: string = 'MY';

  // form-related variables
  loginForm: FormGroup;

  // subscription
  loginSub: Subscription | undefined;

  constructor(private qmMemberService: QmMemberService, private userService: UserService, private messageService: MessageService) {
    this.loginForm = this.initForm();
  }

  onChangeMethod(method: string) {
    this.selectedMethod = method;

    // clear user input
    this.loginForm.controls['email'].setValue('');
    this.loginForm.controls['mobile'].setValue('');
    this.loginForm.controls['username'].setValue('');
    this.loginForm.controls['password'].setValue('');

    // re-init form
    this.loginForm = this.initForm();
  }

  initForm() {
    let formGroup = new FormGroup({
      email: new FormControl(this.email),
      mobile: new FormControl(this.mobile),
      username: new FormControl(this.username),
      password: new FormControl(this.password, [Validators.required])
    });

    switch (this.selectedMethod) {
      case LoginType.EMAIL: // email
        formGroup.controls['email'].setValidators([
          Validators.required,
          Validators.email,
          Validators.maxLength(64),
          validateFormWhitespaceOnly()
        ]);
        formGroup.controls['email'].updateValueAndValidity();
        break;
      case LoginType.MOBILE: // mobile
        formGroup.controls['mobile'].setValidators([
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(14),
          validateFormWhitespaceOnly(),
          validateFormMobile()
        ]);
        formGroup.controls['mobile'].updateValueAndValidity();
        break;
      case LoginType.USERNAME: // username
        formGroup.controls['username'].setValidators([
          Validators.required,
          validateFormWhitespaceOnly()
        ]);
        formGroup.controls['username'].updateValueAndValidity();
        break;
      default:
        break;
    }

    return formGroup;
  }

  onLogin() {
    if (this.loginForm.invalid) {
      alert('invalid');
      console.log('invalid');
    } else {
      this.login();
    }
  }

  login() {
    let formValue = this.loginForm.getRawValue();

    let phoneCode = '';
    let loginId = '';
    let password = formValue.password;

    switch (this.selectedMethod) {
      case LoginType.EMAIL:
        loginId = formValue.email;
        break;
      case LoginType.MOBILE:
        phoneCode = PhoneCode.MYS;
        loginId = formValue.mobile;
        break;
      case LoginType.USERNAME:
        loginId = formValue.username;
        break;
      default:
        break;
    }

    let loginInfo = new LoginInfo(this.selectedMethod, phoneCode, loginId, password);

    this.loginSub = this.qmMemberService.login(loginInfo).subscribe((res: any) => {
      if (res.code === 200) {
        this.userService.setPhoneInfo(PhoneCode.MYS, res.data.phone);
        this.userService.setLoginToken(res.data.token);
        this.userService.redirect('register-phone-verify-member');
      } else {
        console.log(res.msg);
        alert(res.msg);
      }
    });
  }

  onCountrySelected(country: Country) {
    this.phoneCode = country.mobileCode;
    this.countryCode = country.code;
  }

  onBackClick() {
    this.userService.redirect('register');
  }

  ngOnDestroy() {
    this.loginSub?.unsubscribe();
  }
}
