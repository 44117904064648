import { Component } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { Country } from 'src/app/shared/models/country.model';
import { PhoneCode, SignUpProgess } from 'src/app/shared/models/enum.model';
import { validatePhoneNumber } from 'src/app/shared/models/validators.model';
import { environment } from 'src/environments/environment';
import { UserSignupPhoneRegisteredComponent } from '../user-signup-phone-registered/user-signup-phone-registered.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-signup-guest',
  templateUrl: './user-signup-guest.component.html',
  styleUrls: ['./user-signup-guest.component.scss'],
  providers: [DialogService]
})
export class UserSignupGuestComponent {
  tncUrl = environment.TNC_URL;
  readonly PROGRESS = SignUpProgess;

  isSelectPhoneCode: boolean = false;
  countryCode: string = 'MY';
  phoneCode: string = PhoneCode.MYS;
  mobile: string | number = '';
  phoneRegExp: RegExp = /^\d+$/;
  ackChecked: boolean = false;

  mobileRegisteredRef: DynamicDialogRef | undefined;

  verifyPhoneSubscription: Subscription | undefined;

  constructor(private userService: UserService, private dialogService: DialogService, private router: Router, private route: ActivatedRoute) { }

  checkButtonDisabled() {
    if (!validatePhoneNumber(this.mobile) || !this.ackChecked) {
      return true;
    }
    return false;
  }

  onCountrySelected(country: Country) {
    this.phoneCode = country.mobileCode;
    this.countryCode = country.code;
  }

  onVerifyPhone() {
    this.verifyPhoneSubscription = this.userService.verifyPhone(this.mobile.toString()).subscribe((res: any) => {
      if (res.code === 200 && res.data.result === 'unregistered') {
        this.userService.setPhoneInfo(this.phoneCode, this.mobile.toString());
        this.userService.redirect('register-captcha');
      } else {
        this.mobileRegisteredRef = this.dialogService.open(UserSignupPhoneRegisteredComponent, {
          showHeader: false,
          modal: true,
          draggable: false,
          resizable: false,
          closeOnEscape: false,
          styleClass: 'dialog-guest-registered'
        });
      }
    });
  }

  onBackClick() {
    this.router.navigate(['..'], {
      relativeTo: this.route,
      queryParams: {
        callbackUrl: this.userService.getCallbackUrl()
      },
      replaceUrl: true,
    });
  }

  ngOnDestroy() {
    if (this.mobileRegisteredRef) {
      this.mobileRegisteredRef.close();
    }

    this.verifyPhoneSubscription?.unsubscribe();
  }
}
