import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { TimerService } from 'src/app/services/timer.service';
import { UserService } from 'src/app/services/user.service';
import { SendUserSmsCode } from 'src/app/shared/models/api-input.model';

@Component({
  selector: 'app-delete-user-login-otp',
  templateUrl: './delete-user-login-otp.component.html',
  styleUrls: ['./delete-user-login-otp.component.scss'],
  providers: [MessageService]
})
export class DeleteUserLoginOtpComponent {
  hasPasswordLogin: boolean = this.userService.getHasPasswordLogin();

  countdown!: number; // in seconds
  timerSubscription: Subscription | undefined;

  // subscription
  sendCodeSub: Subscription | undefined;
  loginSub: Subscription | undefined;

  constructor(private commonService: CommonService, private messageService: MessageService, private timerService: TimerService, private userService: UserService) {
  }

  redirect(step: string) {
    this.userService.redirect(step);
  }

  onBackClick() {
    this.userService.redirect('login');
  }

  ngOnInit() {
    this.sendCode();
  }

  startTimer() {
    this.countdown = 60;
    this.timerSubscription = this.timerService.getCounter().subscribe(() => {
      --this.countdown!;
      if (this.countdown === 0) {
        this.stopTimer();
      }
    });
  }

  stopTimer() {
    this.timerSubscription?.unsubscribe();
  }

  sendCode() {
    let sendSmsCode = new SendUserSmsCode(
      this.userService.getImageToken(),
      this.userService.getCaptchaX().toString(),
      this.userService.getCaptchaY().toString(),
      this.userService.getPhoneCode(),
      this.userService.getPhone()
    );

    this.sendCodeSub = this.userService.sendCode(sendSmsCode).subscribe((res: any) => {
      this.startTimer();
      if (res.code === 200) {

      } else {
        console.log(res.msg);
        alert(res.msg);
      }
    });
  }

  requestNewOtp(requestNewOtp: boolean) {
    if (requestNewOtp) {
      this.userService.redirect('login-captcha');
    }
  }

  verifyCode(otp: string) {
    this.commonService.showLoader(true);

    this.userService.login({
      phoneCode: this.userService.getPhoneCode(),
      phone: this.userService.getPhone(),
      loginType: 'code',
      code: otp,
      password: '',
      source: this.userService.getLoginSource()
    }).subscribe(res => {
      this.commonService.showLoader(false);
      if (res.code === 200) {
        this.userService.setLoginToken(res.data.token);
        this.redirect('delete-confirm');
      } else {
        this.messageService.add({ detail: 'Verification code error' });
      }
    });
  }

  ngOnDestroy() {
    this.sendCodeSub?.unsubscribe();
    this.timerSubscription?.unsubscribe();
    this.loginSub?.unsubscribe();
  }
}
