import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-delete-user-captcha',
  templateUrl: './delete-user-captcha.component.html',
  styleUrls: ['./delete-user-captcha.component.scss']
})
export class DeleteUserCaptchaComponent {
  isCaptchaVerified: boolean = false;

  redirect(step: string) {
    this.userService.redirect(step);
  }

  onVerifyCaptcha(verified: boolean) {
    this.isCaptchaVerified = verified;
  }

  redirectToLogin() {
    if (this.userService.getHasPasswordLogin()) {
      if (this.userService.getIsPasswordLogin()) {
        this.userService.redirect('login-password');
      } else {
        this.userService.redirect('login-otp');
      }
    } else {
      this.userService.redirect('login-otp');
    }
  }

  onBackClick() {
    this.userService.redirect('login');
  }

  constructor(private userService: UserService) { }
}
