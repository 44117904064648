export enum LoginType {
    'EMAIL' = 'Email',
    'MOBILE' = 'Mobile',
    'USERNAME' = 'Username'
}

export enum PhoneCode {
    'MYS' = '60'
}

export enum Source {
    'MMR' = 'mmr',
    "ECOMMERCE" = 'seller',
    "QMI" = 'qmi',
    "MALL" = 'mall'
}

export enum Gender {
    'MALE' = 'male',
    'FEMALE' = 'female'
}

export enum IdType {
    'MYKAD' = 'MyKad',
    'PASSPORT' = 'Passport'
}

export enum NationalityType {
    'MALAYSIA' = 1,
    'OTHERS' = 2
}

// Progress bar percentage for sign up page
export enum SignUpProgess {
    'SIGNUP_HOME' = 10,
    'SIGNUP_MEMBER' = 20,
    'SIGNUP_GUEST' = 20,
    'SIGNUP_PHONE_VERIFY_MEMBER' = 30,
    'SIGNUP_CAPTCHA' = 40,
    'SIGNUP_OTP' = 50,
    'SIGNUP_PERSONAL_INFO' = 70,
    'SIGNUP_IDENTIFICATION_INFO' = 80,
    'SIGNUP_REFERRAL' = 90
}