<div class="container">
    <div class="text-center mt-8">
        <div class="flex flex-wrap justify-content-center align-items-center font-semibold text-2xl">
            <b>Welcome to MyQM Mall Seller registration form...</b>
            <span class="font-normal">Selamat datang ke halaman pendaftaran MyQM Mall Seller...</span>
        </div>
    </div>

    <div class="mx-auto w-400">
        <div class="flex flex-column gap-3 mt-8">
            <p-button type="submit" styleClass="btn-allow-disable flex justify-content-center w-full" (onClick)="onRedirectSellerPortal()">
                <span class="font-medium text-black-alpha-90">Register MyQM Mall Seller</span>
            </p-button>
        </div>
    </div>
</div>